import { HubSpotEntities, HubspotSubscriptionType } from '../types/hubspot'

export const getHSSubscriptionType = (field?: HubSpotEntities) => {
	if (!field) return ''

	switch (field) {
		case HubSpotEntities.Contact:
			return HubspotSubscriptionType.CONTACT_CREATION
		case HubSpotEntities.Company:
			return HubspotSubscriptionType.COMPANY_CREATION
		default:
			return ''
	}
}
