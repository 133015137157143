import { Box, Center, Flex, HStack, Switch, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { SHADOWS } from '@ds/tokens/shadows'

import { FormTable } from '@/modules/forms/types'
import { Borders } from '@/styles/borders'
import { TEXT_ENUM } from '@/styles/components/text'

import { WorkflowListSkeleton } from './WorkfllowListSkeleton'
import { WorkflowConnectedTo } from './WorkflowConnectTo'
import { WorkflowLogo } from './WorkflowLogo'
import {
	WorkflowMenuActions,
	WorkflowMenuItemType,
} from './WorkflowMenuActions'
import { WorkflowModifyBy } from './WorkflowModifyBy'
import { WorkflowTriggerBy } from './WorkflowTriggerBy'

type Props = {
	form?: FormTable
	handleWorkflowActiveState: (formId: number, active: boolean) => void
	handleNavigateToEditor: (formId: number) => void
	menuItems: WorkflowMenuItemType[]
	isDuplicating?: boolean
}

export const WorkflowListItem = ({
	form,
	handleWorkflowActiveState,
	handleNavigateToEditor,
	menuItems,
	isDuplicating,
}: Props) => {
	if (!form) return null

	if (isDuplicating) return <WorkflowListSkeleton />

	return (
		<Flex
			border={Borders.primary}
			bg="white"
			borderRadius="12px"
			w="full"
			p={4}
			justifyContent="space-between"
			cursor="pointer"
			_hover={{
				bg: COLORS.purple[2],
				borderColor: COLORS.purple[7],
				boxShadow: SHADOWS.elevated,
			}}
			onClick={() => handleNavigateToEditor(form.id)}
		>
			<HStack gap={3} maxW="32%">
				<WorkflowLogo type={form.type} />
				<Box>
					<Text variant={TEXT_ENUM.semibold16} noOfLines={2}>
						{form.workflow_name || form.name || 'Untitled workflow'}
					</Text>
					<Text
						variant={TEXT_ENUM.regular14}
						color={COLORS.background[5]}
						noOfLines={2}
					>
						{form.workflow_description}
					</Text>
				</Box>
			</HStack>

			<HStack gap={3} ml={3}>
				<WorkflowTriggerBy type={form.type} />
				<WorkflowConnectedTo
					type={form.type}
					formName={form.name}
					formId={form.id}
				/>
				<Center h="18px" w="1.25px" bg={COLORS.background[10]} />
				<WorkflowModifyBy
					updatedBy={form.updated_by || form.created_by}
					updatedAt={form.updated_at}
				/>
				<Center
					onClick={(e) => {
						e.stopPropagation()
					}}
				>
					<Switch
						isChecked={form.active}
						onChange={(e) =>
							handleWorkflowActiveState(form.id, e.currentTarget.checked)
						}
					/>
				</Center>
				<WorkflowMenuActions
					variant="list"
					menuItems={menuItems}
					formId={form.id}
				/>
			</HStack>
		</Flex>
	)
}
