import { HubSpotEntities, HubspotSubscriptionType } from '../types/hubspot'

export const getHSRecordType = (type?: HubspotSubscriptionType | null) => {
	if (!type) return ''

	switch (type) {
		case HubspotSubscriptionType.COMPANY_CREATION:
			return HubSpotEntities.Company
		case HubspotSubscriptionType.CONTACT_CREATION:
			return HubSpotEntities.Contact
		default:
			return ''
	}
}
