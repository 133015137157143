import { FieldRefNodeValue, LabeledRefNodeValue, RefNodeValue } from './actions'
import { MatchingCondition, MatchRecord } from './matching'

export interface Salesforce_CreateRecord {
	type: RefNodeValue
	insertSalesforceFields: {
		[salesforceFieldId: string]: FieldRefNodeValue
	}
	isUpsertEnabled: boolean
	upsertFieldData: LabeledRefNodeValue | null
}
export interface Salesforce_MergeRecord {
	type: RefNodeValue // Lead, Contact, Account
	insertSalesforceFields: {
		[salesforceFieldId: string]: FieldRefNodeValue
	}
	duplicateRecordId: LabeledRefNodeValue // the ID of the record being merged
	overwrite: boolean
}

export interface Salesforce_ConvertLead {
	leadId: LabeledRefNodeValue
	accountId: LabeledRefNodeValue
	contactId: LabeledRefNodeValue
	doNotCreateOpportunity: LabeledRefNodeValue
	opportunityName: LabeledRefNodeValue
	overwriteLeadSource: LabeledRefNodeValue
	ownerId: LabeledRefNodeValue
	sendNotificationEmail: LabeledRefNodeValue
}

export interface SFDCConditionInANDBlock {
	condition: MatchingCondition
	real: number
}

export type SFDCMatchConditionANDBlock = SFDCConditionInANDBlock[]

export type Salesforce_MatchRecord = MatchRecord

export enum SalesforceEntities {
	Lead = 'Lead',
	Contact = 'Contact',
	Account = 'Account',
	Opportunity = 'Opportunity',
	OpportunityContactRole = 'OpportunityContactRole',
	Event = 'Event',
	Task = 'Task',
	CampaignMember = 'CampaignMember',
	Case = 'Case',
}

export enum SalesforceDataTypes {
	Address = 'address',
	Base64 = 'base64',
	Boolean = 'boolean',
	Byte = 'byte',
	Calculated = 'calculated',
	Combobox = 'combobox',
	Currency = 'currency',
	DataCategoryGroupReference = 'dataCategoryGroupReference',
	Date = 'date',
	Datetime = 'datetime',
	Double = 'double',
	Email = 'email',
	EncryptedString = 'encryptedstring',
	Floatarray = 'floatarray',
	Id = 'id',
	Int = 'int',
	JunctionIdList = 'junctionidlist',
	Location = 'location',
	Long = 'long',
	MasterRecord = 'masterrecord',
	MultiPicklist = 'multipicklist',
	Percent = 'percent',
	Phone = 'phone',
	Picklist = 'picklist',
	Reference = 'reference',
	String = 'string',
	Textarea = 'textarea',
	Time = 'time',
	Url = 'url',
}
