export enum FeatureFlags {
	salesloft_oAuth = 'salesloft_oAuth',
	'workflows-3-0' = 'workflows-3-0',
	'workflows-legacy' = 'workflows-legacy',
	paid_workflow_triggers = 'paid-workflow-triggers',
	'release-queue-analytics' = 'release-queue-analytics',
	'release-queue-logs' = 'release-queue-logs',
	'display-scheduler-wait-time' = 'display-scheduler-wait-time',
	'amplemarket-integration' = 'amplemarket-integration',
	'disconnected-integrations-allowed' = 'disconnected-integrations-allowed',
	'slate-rich-text-editor' = 'slate-rich-text-editor',
	'workflow-version-viewer' = 'workflow-version-viewer',
	'forms-3' = 'forms-3',
	'workflow-test-runs' = 'workflow-test-runs',
	'workflow-advanced-mode' = 'workflow-advanced-mode',
	'pipeline-bulk-actions' = 'pipeline-bulk-actions',
	'copy-workflow-to' = 'copy-workflow-to',
	'additional-attendees' = 'additional-attendees',
}
