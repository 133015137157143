import { Flex } from '@chakra-ui/react'

export const WorkflowsListLayout = ({
	children,
}: {
	children: React.ReactNode
}) => {
	return (
		<Flex flex={1} gap={3} overflowY="auto" px={6} pb={40} flexDir="column">
			{children}
		</Flex>
	)
}
