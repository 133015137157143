import { useCallback } from 'react'

import { FormType } from '@/modules/forms/types'

import {
	Hubspot_CreateRecord,
	Hubspot_MatchRecord,
	HubSpotEntities,
} from '../types/hubspot'
import { WorkflowIntegrationIds } from '../utils/mappings'
import { useCanvasNodes, useSelectedWorkflow } from './workflow'

export type HubspotRecordOption = {
	label: string
	value: string
	variable: string
}

export const useHubspotRecordOptionsByType = (): {
	getHubspotRecordOptions: (type: HubSpotEntities) => HubspotRecordOption[]
} => {
	const selectedWorkflow = useSelectedWorkflow().selectedWorkflow
	const canvasNodes = useCanvasNodes()?.canvasNodes

	const getHubspotRecordOptions = useCallback(
		(type: HubSpotEntities): HubspotRecordOption[] => {
			const matchRecordOptions: HubspotRecordOption[] = Object.values(
				canvasNodes,
			)
				.filter(
					(n) =>
						n.data.integrationId ===
							WorkflowIntegrationIds.hubspotMatchRecord &&
						(n.data?.stepDetails as Hubspot_MatchRecord)?.type?.value === type,
				)
				.map((n) => ({
					label: `Match from step "${n.data.editableName}"`,
					value: n.data.id,
					variable: '$.id',
				}))

			const createRecordOptions: HubspotRecordOption[] = Object.values(
				canvasNodes,
			)
				.filter(
					(n) =>
						n.data.integrationId ===
							WorkflowIntegrationIds.hubspotCreateRecord &&
						(n.data?.stepDetails as Hubspot_CreateRecord)?.type?.value === type,
				)
				.map((n) => ({
					label: `Created record from step "${n.data.editableName}"`,
					value: n.data.id,
					variable: '$.id',
				}))

			const triggerRecordOptions: HubspotRecordOption[] =
				selectedWorkflow?.type === FormType.hubspot_trigger &&
				selectedWorkflow?.meta?.trigger_config?.subscriptionType
					?.split('.')[0]
					?.toLowerCase() === type.toLowerCase()
					? [
							{
								label: `Created ${
									selectedWorkflow?.meta?.trigger_config?.subscriptionType?.split(
										'.',
									)[0] || 'record'
								} from Workflow Trigger`,
								value: '0',
								variable: '$.triggerRecord.hs_object_id',
							},
						]
					: []

			const recordOptions = [
				...matchRecordOptions,
				...createRecordOptions,
				...triggerRecordOptions,
			]

			if (recordOptions.length > 0) {
				recordOptions.unshift({
					label: `Previous ${type} record`,
					value: 'records',
					variable: `$.hubspotRecords.${type}.id`,
				})
			}

			return recordOptions
		},
		[
			canvasNodes,
			selectedWorkflow?.meta?.trigger_config?.subscriptionType,
			selectedWorkflow?.type,
		],
	)

	return { getHubspotRecordOptions }
}
