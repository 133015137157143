import { cloneDeep } from 'lodash'

import { baseApi } from '@/app/api'
import { responseTransformer } from '@/app/api/helper/response'
import { checklistApi, ChecklistEndpoints } from '@/modules/guide/api'

import {
	CreateFormTablePayload,
	CreateSubmissionDto,
	FormImportPayload,
	FormPublishPayload,
	FormRevertToVersionPayload,
	FormTable,
	FormVersionTable,
	SubmissionWithLead,
	UpdateFormTablePayload,
	UpsertMeetingFormPayload,
} from '../types'
import { QuestionTable } from '../types/questions'
import { questionsApi, QuestionsEndPoints } from './questions'

export enum FormsEndPoints {
	/** Query */
	getAllForms = 'getAllForms',
	getFormById = 'getFormById',
	getFormSubmissions = 'getFormSubmissions',
	getMeetingBookedForm = 'getMeetingBookedForm',
	getPublishedFormById = 'getPublishedFormById',
	getAllFormVersionsById = 'getAllFormVersionsById',
	/** Mutation */
	createForm = 'createForm',
	deleteForm = 'deleteForm',
	updateForm = 'updateForm',
	publishForm = 'publishForm',
	revertToFormVersion = 'revertToFormVersion',
	upsertMeetingBookedForm = 'upsertMeetingBookedForm',
	importForm = 'importForm',
	duplicateForm = 'duplicateForm',
	createTestSubmission = 'createTestSubmission',
}

const formsApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		[FormsEndPoints.getFormById]: builder.query<
			FormTable,
			string | null | undefined
		>({
			query: (formId) => ({
				url: `/forms/${formId}`,
				responseHandler: responseTransformer,
			}),
			providesTags: ['GET_FORM_BY_ID'],
		}),
		[FormsEndPoints.getMeetingBookedForm]: builder.query<FormTable, void>({
			query: () => ({
				url: `/forms/meeting-booked`,
				responseHandler: responseTransformer,
			}),
			providesTags: ['GET_MEETING_BOOKED_FORM'],
		}),

		[FormsEndPoints.getAllForms]: builder.query<FormTable[], void>({
			query: () => ({
				url: `/forms`,
				responseHandler: responseTransformer,
			}),
			providesTags: ['GET_ALL_FORMS'],
		}),

		[FormsEndPoints.getFormSubmissions]: builder.query<
			SubmissionWithLead[],
			string | number | null | undefined
		>({
			query: (formId) => ({
				url: `/forms/${formId}/submissions`,
				responseHandler: responseTransformer,
			}),
		}),
		[FormsEndPoints.getPublishedFormById]: builder.query<
			FormTable,
			string | null | undefined
		>({
			query: (formId) => ({
				url: `/forms/versions/form/${formId}`,
				responseHandler: responseTransformer,
			}),
			providesTags: ['GET_PUBLISHED_FORM_BY_ID'],
		}),
		[FormsEndPoints.getAllFormVersionsById]: builder.query<
			FormVersionTable[],
			string | null | undefined
		>({
			query: (formId) => ({
				url: `forms/versions/form/${formId}/all-versions`,
				responseHandler: responseTransformer,
			}),
			providesTags: ['GET_ALL_FORM_VERSIONS_BY_ID'],
		}),

		/** Mutations */
		[FormsEndPoints.createForm]: builder.mutation<
			FormTable,
			CreateFormTablePayload
		>({
			query: (payload) => ({
				url: `/forms`,
				method: 'POST',
				body: payload,
				responseHandler: responseTransformer,
			}),
			invalidatesTags: ['GET_ALL_FORMS'],
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchAllForms = dispatch(
					formsApi.util.updateQueryData(
						FormsEndPoints.getAllForms,
						undefined,
						(draft) => {
							const newForm = {
								...arg.form,
							} as FormTable
							draft.unshift(newForm)
							return draft
						},
					),
				)

				const patchChecklist = dispatch(
					checklistApi.util.updateQueryData(
						ChecklistEndpoints.getChecklist,
						undefined,
						(draft) => {
							return {
								...draft,
								isFormCreated: true,
							}
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchAllForms.undo()
					patchChecklist.undo()
				}
			},
		}),

		[FormsEndPoints.updateForm]: builder.mutation<
			FormTable,
			UpdateFormTablePayload
		>({
			query: (payload) => ({
				url: `/forms/${payload.form.id}`,
				method: 'PATCH',
				body: { form: { ...payload.form, published: false } },
				responseHandler: responseTransformer,
			}),

			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchFormById = dispatch(
					formsApi.util.updateQueryData(
						FormsEndPoints.getFormById,
						arg.form.id.toString(),
						(oldForm) => {
							return {
								...oldForm,
								...arg.form,
								published: false,
							}
						},
					),
				)

				const patchAllForms = dispatch(
					formsApi.util.updateQueryData(
						FormsEndPoints.getAllForms,
						undefined,
						(allForms) => {
							const index = allForms.findIndex(
								(form) => form.id === arg.form.id,
							)

							if (index === -1) return allForms
							const oldForm = allForms[index]
							const newForm = {
								...oldForm,
								...arg.form,
							}
							allForms[index] = newForm
							return allForms
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchAllForms.undo()
					patchFormById.undo()
				}
			},
		}),

		[FormsEndPoints.deleteForm]: builder.mutation<
			FormTable,
			number | string | null | undefined
		>({
			query: (formId) => ({
				url: `/forms/${formId}`,
				method: 'DELETE',
				responseHandler: responseTransformer,
			}),

			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchResult = dispatch(
					formsApi.util.updateQueryData(
						FormsEndPoints.getAllForms,
						undefined,
						(allForms) => {
							return allForms.filter((form) => form.id !== arg)
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchResult.undo()
				}
			},
		}),

		[FormsEndPoints.publishForm]: builder.mutation<any, FormPublishPayload>({
			query: (payload) => ({
				url: `/forms/${payload.formId}/publish`,
				method: 'POST',
			}),
			invalidatesTags: ['GET_FORM_QUESTIONS'],
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchFormById = dispatch(
					formsApi.util.updateQueryData(
						FormsEndPoints.getFormById,
						arg.formId?.toString(),
						(oldForm) => {
							return {
								...oldForm,
								published: true,
							}
						},
					),
				)

				const patchChecklist = dispatch(
					checklistApi.util.updateQueryData(
						ChecklistEndpoints.getChecklist,
						undefined,
						(draft) => {
							return {
								...draft,
								isFormPublished: true,
							}
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchFormById.undo()
					patchChecklist.undo()
				}
			},
		}),
		[FormsEndPoints.revertToFormVersion]: builder.mutation<
			{ form: FormTable; questions: QuestionTable[] },
			FormRevertToVersionPayload
		>({
			query: (payload) => ({
				url: `/forms/${payload.formId}/revert`,
				method: 'POST',
				responseHandler: responseTransformer,
			}),
			invalidatesTags: [
				'GET_FORM_BY_ID',
				'GET_FORM_QUESTIONS',
				'GET_ALL_IMPORTED_QUESTIONS_BY_FORM_ID',
			],
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				try {
					const { data } = await queryFulfilled

					// patch form
					dispatch(
						formsApi.util.updateQueryData(
							FormsEndPoints.getFormById,
							arg.formId?.toString(),
							() => data.form,
						),
					)

					// patch questions
					dispatch(
						questionsApi.util.updateQueryData(
							QuestionsEndPoints.getQuestionsByFormId,
							arg.formId?.toString(),
							() => data.questions,
						),
					)
				} catch (e) {
					// we don't have to anything in the UI here
					console.error(e)
				}
			},
		}),
		[FormsEndPoints.upsertMeetingBookedForm]: builder.mutation<
			FormTable,
			UpsertMeetingFormPayload
		>({
			query: (payload) => ({
				url: `/forms/meeting-booked`,
				method: 'POST',
				body: payload,
				responseHandler: responseTransformer,
			}),
			invalidatesTags: ['GET_MEETING_BOOKED_FORM'],
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchMeetingBookedForm = dispatch(
					formsApi.util.updateQueryData(
						FormsEndPoints.getMeetingBookedForm,
						undefined,
						(draft) => {
							const newForm = {
								...draft,
								...arg.form,
							}
							return newForm
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchMeetingBookedForm.undo()
				}
			},
		}),
		[FormsEndPoints.importForm]: builder.mutation<boolean, FormImportPayload>({
			query: ({ questions, meta, formId, name }) => ({
				url: `/forms/imported/${formId}`,
				method: 'POST',
				body: { imported: { questions, meta, name } },
			}),
			invalidatesTags: [
				'GET_PUBLISHED_FORM_BY_ID',
				'GET_FORM_BY_ID',
				'GET_ALL_IMPORTED_QUESTIONS_BY_FORM_ID',
			],
		}),
		[FormsEndPoints.duplicateForm]: builder.mutation<
			FormTable,
			string | number | null | undefined
		>({
			query: (formId) => ({
				url: `/forms/${formId}/duplicate`,
				method: 'POST',
				responseHandler: responseTransformer,
			}),
			invalidatesTags: ['GET_ALL_FORMS'],
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchAllForms = dispatch(
					formsApi.util.updateQueryData(
						FormsEndPoints.getAllForms,
						undefined,
						(draft) => {
							const foundForm = draft.find((e) => e.id === arg)
							if (foundForm) {
								const newForm = cloneDeep(foundForm)
								newForm.name = `${foundForm.name} Copy`
								newForm.id = 0
								draft.unshift(newForm)
							}

							return draft
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchAllForms.undo()
				}
			},
		}),
		[FormsEndPoints.createTestSubmission]: builder.mutation<
			SubmissionWithLead,
			{ submission: CreateSubmissionDto }
		>({
			query: (payload) => ({
				url: `forms/submissions/test/create`,
				method: 'POST',
				body: payload,
				responseHandler: responseTransformer,
			}),
		}),
	}),
})

export const {
	useGetMeetingBookedFormQuery,
	useGetFormByIdQuery,
	useLazyGetFormByIdQuery,
	useCreateFormMutation,
	useGetAllFormsQuery,
	useGetPublishedFormByIdQuery,
	useUpdateFormMutation,
	useDeleteFormMutation,
	useGetFormSubmissionsQuery,
	usePublishFormMutation,
	useRevertToFormVersionMutation,
	useUpsertMeetingBookedFormMutation,
	useImportFormMutation,
	useDuplicateFormMutation,
	useGetAllFormVersionsByIdQuery,
	useCreateTestSubmissionMutation,
} = formsApi

export { formsApi }
