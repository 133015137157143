import { Flex } from '@chakra-ui/react'
import { FONT_SIZES } from '@ds/index'
import { isEqual } from 'lodash'
import { useEffect } from 'react'
import React from 'react'
import { useDispatch } from 'react-redux'

import { QuestionTable } from '@/modules/forms/types/questions'
import { TestSubmissionResponse } from '@/modules/submission/types'
import { workflowsActions } from '@/modules/workflow/slice'

import { PanelInput } from '../../panel-variants/PanelInput'
import { PanelInputLabel } from '../../panel-variants/PanelInputLabel'
import { PanelSelect } from '../../panel-variants/PanelSelect'

interface TestQuestionInputProps {
	initialValue: string[]
	question: QuestionTable
	response?: TestSubmissionResponse
}

export const TestQuestionInput = React.memo(
	({ initialValue, question, response }: TestQuestionInputProps) => {
		const dispatch = useDispatch()

		const handleInputChange = (questionId, value) => {
			dispatch(
				workflowsActions.updateTestWorkflowFormData({
					questionId,
					value,
				}),
			)
		}

		useEffect(() => {
			switch (question.question_type) {
				case 'Single Select':
					dispatch(
						workflowsActions.updateTestWorkflowFormData({
							questionId: question.id.toString(),
							value: response?.value[0] || '',
						}),
					)
					break
				case 'Multi Select':
					dispatch(
						workflowsActions.updateTestWorkflowFormData({
							questionId: question.id.toString(),
							value: response?.value || '',
						}),
					)
					break
				default:
					dispatch(
						workflowsActions.updateTestWorkflowFormData({
							questionId: question.id.toString(),
							value: response?.value[0] || '',
						}),
					)
					break
			}
		}, [response])

		return (
			<Flex key={question.id} direction="column">
				<PanelInputLabel
					label={question?.title || ''}
					fontSize={FONT_SIZES.xs}
				/>
				{question.question_type !== 'Single Select' &&
				question.question_type !== 'Multi Select' ? (
					<PanelInput
						initialValue={initialValue[0] || ''}
						onBlur={(e) => handleInputChange(question.id, e.target.value)}
					/>
				) : (
					<PanelSelect
						value={initialValue}
						isMulti={question.question_type === 'Multi Select'}
						// @ts-ignore
						options={question.question_options.map((option) => ({
							label: option,
							value: option,
						}))}
						onChange={(e) => {
							handleInputChange(question.id, e)
						}}
					/>
				)}
			</Flex>
		)
	},
	(prevProps, nextProps) => {
		return (
			isEqual(prevProps.initialValue, nextProps.initialValue) &&
			isEqual(prevProps.question, nextProps.question) &&
			isEqual(prevProps.response, nextProps.response)
		)
	},
)
