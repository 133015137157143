import { Icons } from 'ui'

import Amplemarket from '@/assets/logo/amplemarket.png'
import Apollo from '@/assets/logo/apollo.png'
import Clearbit from '@/assets/logo/clearbit-logo.svg'
import Harmonic from '@/assets/logo/harmonic.svg'
import HubSpot from '@/assets/logo/hubspot.svg'
import Loops from '@/assets/logo/loops-logo.svg'
import Outreach from '@/assets/logo/outreach.svg'
import Salesforce from '@/assets/logo/salesforce.svg'
import Salesloft from '@/assets/logo/salesloft.svg'
import Slack from '@/assets/logo/slack.svg'
import Zoom from '@/assets/logo/zoom.png'
import { FormType } from '@/modules/forms/types'

import { DEFAULT_SALESFORCE_CONVERT_LEAD_WF_STEP_DETAILS } from '../../components/side-panel/panel-content/step-details/integrations/salesforce/SalesforceConvertLead/step-detail'
import { DEFAULT_SLACK_SEND_MESSAGE_STEP_DETAILS } from '../../components/side-panel/panel-content/step-details/integrations/slack/step-detail'
import { WorkflowIntegrationsMapType } from '../../types/integrations'
import { RFNodeType, StepType } from '../../types/nodes'
import { createLogicStepDetails } from '../graph-operations'

export enum WorkflowIntegrationIds {
	ifElse = 'ifElse',
	defaultAddTag = 'defaultAddTag',
	defaultRoute = 'defaultRoute',
	defaultRedirectToUrl = 'defaultRedirectToUrl',
	defaultDisplayScheduler = 'defaultDisplayScheduler',
	defaultSendToWebhook = 'defaultSendToWebhook',
	defaultWaitNode = 'defaultWaitNode',
	slackSendMessage = 'slackSendMessage',
	salesforceCreateRecord = 'salesforceCreateRecord',
	salesforceMatchRecord = 'salesforceMatchRecord',
	salesforceMergeRecord = 'salesforceMergeRecord',
	salesforceConvertLead = 'salesforceConvertLead',
	hubspotCreateRecord = 'hubspotCreateRecord',
	hubspotMergeRecord = 'hubspotMergeRecord',
	hubspotMatchRecord = 'hubspotMatchRecord',
	hubspotCreateEngagement = 'hubspotCreateEngagement',
	outreachStartSequence = 'outreachStartSequence',
	salesloftStartCadence = 'salesloftStartCadence',
	loops = 'loops',
	harmonic = 'harmonicEnrich',
	apolloEnrich = 'apolloEnrich',
	clearbitEnrich = 'clearbitEnrich',
	apolloStartSequence = 'apolloStartSequence',
	zoomAddToWebinar = 'zoomAddToWebinar',
	aiPrompt = 'aiPrompt',
	amplemarketEnrich = 'amplemarketEnrich',
	amplemarketStartInboundSmartAction = 'amplemarketStartInboundSmartAction',
}

export enum WorkflowIntegrationType {
	Empty = '',
	Logic = 'Logic',
	Default = 'Default',
	Http = 'HTTP',
	Salesforce = 'Salesforce',
	HubSpot = 'HubSpot',
	Outreach = 'Outreach',
	Slack = 'Slack',
	Salesloft = 'Salesloft',
	Loops = 'Loops',
	Harmonic = 'Harmonic.ai',
	Apollo = 'Apollo',
	Clearbit = 'Clearbit',
	Zoom = 'Zoom',
	AiAction = 'AI Action',
	Amplemarket = 'Amplemarket',
}

export enum WorkflowIntegrationCategory {
	Action = 'action',
	Logic = 'logic',
	Empty = 'empty',
	WorkflowStart = 'workflowStart',
	AiAction = 'aiAction',
	Scheduling = 'scheduling',
	Salesforce = 'salesforce',
	HubSpot = 'hubspot',
	Notification = 'notification',
	Sequence = 'sequence',
	Enrichment = 'enrichment',
	Loops = 'loops',
	Zoom = 'zoom',
}

export const WorkflowIntegrationsMap: WorkflowIntegrationsMapType = {
	ifElse: {
		integrationId: WorkflowIntegrationIds.ifElse,
		integrationName: WorkflowIntegrationType.Logic,
		integrationCategory: WorkflowIntegrationCategory.Logic,
		logo: Icons.ifElse,
		isIcon: true,
		integrationAction: 'If / Else',
		stepDetails: createLogicStepDetails(WorkflowIntegrationIds.ifElse),
		nodeType: RFNodeType.logicNode,
		stepType: StepType.Logic,
	},
	defaultAddTag: {
		integrationId: WorkflowIntegrationIds.defaultAddTag,
		integrationName: WorkflowIntegrationType.Default,
		integrationCategory: WorkflowIntegrationCategory.Action,
		logo: Icons.tag,
		isIcon: true,
		integrationAction: 'Add Tag to Lead',
		stepDetails: {},
		nodeType: RFNodeType.actionNode,
		stepType: StepType.Action,
	},
	defaultRoute: {
		integrationId: WorkflowIntegrationIds.defaultRoute,
		integrationName: WorkflowIntegrationType.Default,
		integrationCategory: WorkflowIntegrationCategory.Action,
		logo: Icons.arrows_clockwise,
		isIcon: true,
		integrationAction: 'Round-Robin (Route)',
		stepDetails: {},
		nodeType: RFNodeType.actionNode,
		stepType: StepType.Action,
	},
	defaultRedirectToUrl: {
		integrationId: WorkflowIntegrationIds.defaultRedirectToUrl,
		integrationName: WorkflowIntegrationType.Default,
		integrationCategory: WorkflowIntegrationCategory.Action,
		logo: Icons.link,
		isIcon: true,
		integrationAction: 'Redirect to URL',
		stepDetails: {},
		nodeType: RFNodeType.actionNode,
		stepType: StepType.Action,
		validFormTypes: [FormType.regular, FormType.imported],
	},
	defaultDisplayScheduler: {
		integrationId: WorkflowIntegrationIds.defaultDisplayScheduler,
		integrationName: WorkflowIntegrationType.Default,
		integrationCategory: WorkflowIntegrationCategory.Scheduling,
		logo: Icons.calendar,
		isIcon: true,
		integrationAction: 'Display Scheduler',
		stepDetails: createLogicStepDetails(
			WorkflowIntegrationIds.defaultDisplayScheduler,
		),
		nodeType: RFNodeType.logicNode,
		stepType: StepType.Logic,
		validFormTypes: [FormType.regular, FormType.imported],
	},
	defaultSendToWebhook: {
		integrationId: WorkflowIntegrationIds.defaultSendToWebhook,
		integrationName: WorkflowIntegrationType.Http,
		integrationCategory: WorkflowIntegrationCategory.Action,
		logo: Icons.webhook,
		isIcon: true,
		integrationAction: 'Send to Webhook',
		stepDetails: {},
		nodeType: RFNodeType.actionNode,
		stepType: StepType.Action,
	},
	defaultWaitNode: {
		integrationId: WorkflowIntegrationIds.defaultWaitNode,
		integrationName: WorkflowIntegrationType.Default,
		integrationCategory: WorkflowIntegrationCategory.Action,
		logo: Icons.clock,
		isIcon: true,
		integrationAction: 'Time Delay',
		stepDetails: {},
		nodeType: RFNodeType.actionNode,
		stepType: StepType.Action,
	},
	salesforceCreateRecord: {
		integrationId: WorkflowIntegrationIds.salesforceCreateRecord,
		integrationName: WorkflowIntegrationType.Salesforce,
		integrationCategory: WorkflowIntegrationCategory.Salesforce,
		logo: Salesforce,
		isIcon: false,
		integrationAction: 'Create Salesforce Record',
		stepDetails: {},
		nodeType: RFNodeType.actionNode,
		stepType: StepType.Action,
	},
	salesforceMergeRecord: {
		integrationId: WorkflowIntegrationIds.salesforceMergeRecord,
		integrationName: WorkflowIntegrationType.Salesforce,
		integrationCategory: WorkflowIntegrationCategory.Salesforce,
		logo: Salesforce,
		isIcon: false,
		integrationAction: 'Update Salesforce Record',
		stepDetails: {},
		nodeType: RFNodeType.actionNode,
		stepType: StepType.Action,
	},
	salesforceMatchRecord: {
		integrationId: WorkflowIntegrationIds.salesforceMatchRecord,
		integrationName: WorkflowIntegrationType.Salesforce,
		integrationCategory: WorkflowIntegrationCategory.Salesforce,
		logo: Salesforce,
		isIcon: false,
		integrationAction: 'Match Salesforce Record',
		stepDetails: createLogicStepDetails(
			WorkflowIntegrationIds.salesforceMatchRecord,
		),
		nodeType: RFNodeType.logicNode,
		stepType: StepType.Logic,
	},
	salesforceConvertLead: {
		integrationId: WorkflowIntegrationIds.salesforceConvertLead,
		integrationName: WorkflowIntegrationType.Salesforce,
		integrationCategory: WorkflowIntegrationCategory.Salesforce,
		logo: Salesforce,
		isIcon: false,
		integrationAction: 'Convert Salesforce Lead',
		stepDetails: DEFAULT_SALESFORCE_CONVERT_LEAD_WF_STEP_DETAILS,
		nodeType: RFNodeType.actionNode,
		stepType: StepType.Action,
	},
	slackSendMessage: {
		integrationId: WorkflowIntegrationIds.slackSendMessage,
		integrationName: WorkflowIntegrationType.Slack,
		integrationCategory: WorkflowIntegrationCategory.Notification,
		logo: Slack,
		isIcon: false,
		integrationAction: 'Send Slack Message',
		stepDetails: DEFAULT_SLACK_SEND_MESSAGE_STEP_DETAILS,
		nodeType: RFNodeType.actionNode,
		stepType: StepType.Action,
	},
	hubspotCreateRecord: {
		integrationId: WorkflowIntegrationIds.hubspotCreateRecord,
		integrationName: WorkflowIntegrationType.HubSpot,
		integrationCategory: WorkflowIntegrationCategory.HubSpot,
		logo: HubSpot,
		isIcon: false,
		integrationAction: 'Create HubSpot Record',
		stepDetails: {},
		nodeType: RFNodeType.actionNode,
		stepType: StepType.Action,
	},
	hubspotMergeRecord: {
		integrationId: WorkflowIntegrationIds.hubspotMergeRecord,
		integrationName: WorkflowIntegrationType.HubSpot,
		integrationCategory: WorkflowIntegrationCategory.HubSpot,
		logo: HubSpot,
		isIcon: false,
		integrationAction: 'Update HubSpot Record',
		stepDetails: {},
		nodeType: RFNodeType.actionNode,
		stepType: StepType.Action,
	},
	hubspotMatchRecord: {
		integrationId: WorkflowIntegrationIds.hubspotMatchRecord,
		integrationName: WorkflowIntegrationType.HubSpot,
		integrationCategory: WorkflowIntegrationCategory.HubSpot,
		logo: HubSpot,
		isIcon: false,
		integrationAction: 'Match HubSpot Record',
		stepDetails: createLogicStepDetails(
			WorkflowIntegrationIds.hubspotMatchRecord,
		),
		nodeType: RFNodeType.logicNode,
		stepType: StepType.Logic,
	},
	hubspotCreateEngagement: {
		integrationId: WorkflowIntegrationIds.hubspotCreateEngagement,
		integrationName: WorkflowIntegrationType.HubSpot,
		integrationCategory: WorkflowIntegrationCategory.HubSpot,
		logo: HubSpot,
		isIcon: false,
		integrationAction: 'Create HubSpot Engagement',
		stepDetails: {},
		nodeType: RFNodeType.actionNode,
		stepType: StepType.Action,
	},
	outreachStartSequence: {
		integrationId: WorkflowIntegrationIds.outreachStartSequence,
		integrationName: WorkflowIntegrationType.Outreach,
		integrationCategory: WorkflowIntegrationCategory.Sequence,
		logo: Outreach,
		isIcon: false,
		integrationAction: 'Start Outreach Sequence',
		stepDetails: {},
		nodeType: RFNodeType.actionNode,
		stepType: StepType.Action,
	},
	salesloftStartCadence: {
		integrationId: WorkflowIntegrationIds.salesloftStartCadence,
		integrationName: WorkflowIntegrationType.Salesloft,
		integrationCategory: WorkflowIntegrationCategory.Sequence,
		logo: Salesloft,
		isIcon: false,
		integrationAction: 'Start Salesloft Cadence',
		stepDetails: {},
		nodeType: RFNodeType.actionNode,
		stepType: StepType.Action,
	},
	loops: {
		integrationId: WorkflowIntegrationIds.loops,
		integrationName: WorkflowIntegrationType.Loops,
		integrationCategory: WorkflowIntegrationCategory.Loops,
		logo: Loops,
		isIcon: false,
		integrationAction: 'Create Loops Contact',
		stepDetails: {},
		nodeType: RFNodeType.actionNode,
		stepType: StepType.Action,
	},
	harmonicEnrich: {
		integrationId: WorkflowIntegrationIds.harmonic,
		integrationName: WorkflowIntegrationType.Harmonic,
		integrationCategory: WorkflowIntegrationCategory.Enrichment,
		logo: Harmonic,
		isIcon: false,
		integrationAction: 'Enrich with Harmonic',
		stepDetails: {},
		nodeType: RFNodeType.actionNode,
		stepType: StepType.Action,
	},

	apolloEnrich: {
		integrationId: WorkflowIntegrationIds.apolloEnrich,
		integrationName: WorkflowIntegrationType.Apollo,
		integrationCategory: WorkflowIntegrationCategory.Enrichment,
		logo: Apollo,
		isIcon: false,
		integrationAction: 'Enrich with Apollo',
		stepDetails: {},
		nodeType: RFNodeType.actionNode,
		stepType: StepType.Action,
	},
	clearbitEnrich: {
		integrationId: WorkflowIntegrationIds.clearbitEnrich,
		integrationName: WorkflowIntegrationType.Clearbit,
		integrationCategory: WorkflowIntegrationCategory.Enrichment,
		logo: Clearbit,
		isIcon: false,
		integrationAction: 'Enrich with Clearbit',
		stepDetails: {},
		nodeType: RFNodeType.actionNode,
		stepType: StepType.Action,
		validFormTypes: Object.values(FormType).filter(
			(formType) =>
				formType !== FormType.regular && formType !== FormType.imported,
		),
	},
	apolloStartSequence: {
		integrationId: WorkflowIntegrationIds.apolloStartSequence,
		integrationName: WorkflowIntegrationType.Apollo,
		integrationCategory: WorkflowIntegrationCategory.Sequence,
		logo: Apollo,
		isIcon: false,
		integrationAction: 'Start Apollo Sequence',
		stepDetails: {},
		nodeType: RFNodeType.actionNode,
		stepType: StepType.Action,
	},
	zoomAddToWebinar: {
		integrationId: WorkflowIntegrationIds.zoomAddToWebinar,
		integrationName: WorkflowIntegrationType.Zoom,
		integrationCategory: WorkflowIntegrationCategory.Zoom,
		logo: Zoom,
		isIcon: false,
		integrationAction: 'Add to Zoom Webinar',
		stepDetails: {},
		nodeType: RFNodeType.actionNode,
		stepType: StepType.Action,
	},
	aiPrompt: {
		integrationId: WorkflowIntegrationIds.aiPrompt,
		integrationName: WorkflowIntegrationType.AiAction,
		integrationCategory: WorkflowIntegrationCategory.AiAction,
		logo: Icons.sparkles,
		isIcon: true,
		integrationAction: 'AI Prompt',
		stepDetails: {},
		nodeType: RFNodeType.actionNode,
		stepType: StepType.Action,
	},
	amplemarketEnrich: {
		integrationId: WorkflowIntegrationIds.amplemarketEnrich,
		integrationName: WorkflowIntegrationType.Amplemarket,
		integrationCategory: WorkflowIntegrationCategory.Enrichment,
		logo: Amplemarket,
		isIcon: false,
		integrationAction: 'Enrich with Amplemarket',
		stepDetails: {},
		nodeType: RFNodeType.actionNode,
		stepType: StepType.Action,
	},
	amplemarketStartInboundSmartAction: {
		integrationId: WorkflowIntegrationIds.amplemarketStartInboundSmartAction,
		integrationName: WorkflowIntegrationType.Amplemarket,
		integrationCategory: WorkflowIntegrationCategory.Sequence,
		logo: Amplemarket,
		isIcon: false,
		integrationAction: 'Start Amplemarket Smart Action',
		stepDetails: {},
		nodeType: RFNodeType.actionNode,
		stepType: StepType.Action,
	},
}
