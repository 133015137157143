import '@/styles/components/RichTextEditor.css'

import { Button, Flex, FlexProps, Text, useColorMode } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'
import Mention from '@tiptap/extension-mention'
import { Editor, EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import React, {
	CSSProperties,
	MouseEventHandler,
	useEffect,
	useRef,
	useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { GetIcon, Icons } from 'ui'

import { userActions } from '@/app/user/slice'
import { DataReferencePicker } from '@/modules/workflow/components/side-panel/panel-content/DataReferencePicker'
import { useGetSlackMessageDrpOptions } from '@/modules/workflow/hooks/drp-options/aggregators/useGetSlackMessageDrpOptions'
import { useGetParameterInfoCallback } from '@/modules/workflow/hooks/useGetParameterInfoCallback'

import { MentionListItem } from './MentionList'
import suggestion from './suggestion'

interface EditingBarProps {
	editor: Editor
	disabledEditingBarButtons?: EditingBarButtonType[]
}

const EditingBar = ({ editor, disabledEditingBarButtons }: EditingBarProps) => {
	if (!editor) {
		return null
	}

	return (
		<Flex borderRadius="8px 8px 0px 0px" p="4px" bg={ColorTokens.white}>
			<Flex align="center" bg="transparent" w="100%" borderRadius="8px" p="4px">
				{!disabledEditingBarButtons?.includes('bold') && (
					<Button
						h="28px"
						w="32px"
						minW="32px"
						fontWeight={700}
						fontSize="16px"
						onClick={() => editor.chain().focus().toggleBold().run()}
						bg={editor.isActive('bold') ? COLORS.purple[4] : ''}
						_hover={{
							bg: editor.isActive('bold') ? '' : COLORS.purple[4],
						}}
						mr="4px"
					>
						<GetIcon icon={Icons.bold} boxSize={5} />
					</Button>
				)}

				{!disabledEditingBarButtons?.includes('italic') && (
					<Button
						h="28px"
						w="32px"
						minW="32px"
						fontSize="16px"
						mr="4px"
						onClick={() => editor.chain().focus().toggleItalic().run()}
						bg={editor.isActive('italic') ? COLORS.purple[4] : ''}
						_hover={{
							bg: editor.isActive('italic') ? '' : COLORS.purple[4],
						}}
					>
						<GetIcon icon={Icons.italics} boxSize={5} />
					</Button>
				)}

				{!disabledEditingBarButtons?.includes('strikethrough') && (
					<Button
						h="28px"
						w="32px"
						minW="32px"
						fontSize="16px"
						textDecor="line-through"
						mr="4px"
						onClick={() => editor.chain().focus().toggleStrike().run()}
						bg={editor.isActive('strike') ? COLORS.purple[4] : ''}
						_hover={{
							bg: editor.isActive('strike') ? '' : COLORS.purple[4],
						}}
					>
						<GetIcon icon={Icons.striked_text} boxSize={5} />
					</Button>
				)}

				{/* <Flex h="18px" mr="8px" ml="4px" w="1px" bg={ColorTokens.border_primary} /> */}

				{!disabledEditingBarButtons?.includes('bulleted-list') && (
					<Button
						h="28px"
						w="32px"
						minW="32px"
						onClick={() => editor.chain().focus().toggleBulletList().run()}
						bg={editor.isActive('bulletList') ? COLORS.purple[4] : ''}
						_hover={{
							bg: editor.isActive('bulletList') ? '' : COLORS.purple[4],
						}}
						mr="4px"
					>
						<GetIcon icon={Icons.bullet_list} boxSize={5} />
					</Button>
				)}

				{!disabledEditingBarButtons?.includes('numbered-list') && (
					<Button
						h="28px"
						w="32px"
						minW="32px"
						onClick={() => {
							editor.chain().focus().toggleOrderedList().run()
						}}
						bg={editor.isActive('orderedList') ? COLORS.purple[4] : ''}
						_hover={{
							bg: editor.isActive('orderedList') ? '' : COLORS.purple[4],
						}}
						mr="4px"
					>
						<GetIcon icon={Icons.numbered_list} boxSize={5} />
					</Button>
				)}

				{!disabledEditingBarButtons?.includes('blockquote') && (
					<Button
						h="28px"
						w="32px"
						minW="32px"
						onClick={() => editor.chain().focus().toggleBlockquote().run()}
						bg={editor.isActive('blockquote') ? COLORS.purple[4] : ''}
						_hover={{
							bg: editor.isActive('blockquote') ? '' : COLORS.purple[4],
						}}
						mr="4px"
					>
						<GetIcon icon={Icons.quotes} boxSize={4} />
					</Button>
				)}

				{!disabledEditingBarButtons?.includes('variable') && (
					<Button
						h="28px"
						w="32px"
						minW="32px"
						onClick={() => {
							editor
								.chain()
								.focus()
								.insertContentAt(editor.state.selection, ' {{')
								.run()
						}}
						bg={editor.isActive('link') ? COLORS.purple[4] : ''}
						_hover={{
							bg: editor.isActive('link') ? '' : COLORS.purple[4],
						}}
						mr="4px"
					>
						{/* <GetIcon icon={Icons.link_2} boxSize={5} /> */}
						<Text fontSize="14px">{'{{}}'}</Text>
					</Button>
				)}

				{!disabledEditingBarButtons?.includes('undo') && (
					<Button
						h="28px"
						w="32px"
						minW="32px"
						onClick={() => editor.chain().focus().undo().run()}
						bg="rgba(0,0,0,0)"
						_hover={{ bg: COLORS.purple[4] }}
						mr="4px"
						ml="auto"
					>
						<GetIcon icon={Icons.go_back} />
					</Button>
				)}

				{!disabledEditingBarButtons?.includes('redo') && (
					<Button
						h="28px"
						w="32px"
						minW="32px"
						onClick={() => editor.chain().focus().redo().run()}
						bg="rgba(0,0,0,0)"
						_hover={{ bg: COLORS.purple[4] }}
					>
						<GetIcon icon={Icons.go_forward} />
					</Button>
				)}
			</Flex>
		</Flex>
	)
}

type EditingBarButtonType =
	| 'bold'
	| 'italic'
	| 'strikethrough'
	| 'bulleted-list'
	| 'numbered-list'
	| 'blockquote'
	| 'link'
	| 'undo'
	| 'redo'
	| 'variable'

interface EditorProps extends Omit<FlexProps, 'onBlur' | 'onChange'> {
	onChange: (value: string) => void
	onBlur?: (value: string) => void
	minH?: string
	onClickEnter?: (value: string) => void
	// setter: (arg: string) => void
	bodyText: string
	hasChanged: boolean
	editorCss?: CSSProperties
	editorClassName?: string
	suggestionOptions?: MentionListItem[]
	disableEditingBar?: boolean
	disabledEditingBarButtons?: EditingBarButtonType[]
	showSendButton?: boolean
	useDrp?: boolean
	placeholder?: string
}
export const RichTextEditor = ({
	// setter,
	onChange,
	onBlur,
	onClickEnter,
	bodyText,
	minH,
	hasChanged,
	editorCss,
	editorClassName,
	disableEditingBar,
	suggestionOptions,
	disabledEditingBarButtons,
	showSendButton,
	useDrp,
	...props
}: EditorProps) => {
	const textColor = ColorTokens.text
	const hyperlinkColor = ColorTokens.accent
	const { colorMode } = useColorMode()
	const [focused, setFocused] = useState(false)

	const [isEditingLink, setIsEditingLink] = useState(false)
	const insideTooltipReference = useRef(false)
	const inputRef = React.createRef<HTMLInputElement>()

	const getInitialDrpOptions = useGetSlackMessageDrpOptions()
	const getParameterInfoCallback = useGetParameterInfoCallback()
	const dispatch = useDispatch()

	const isSelectingReference = useRef(false)

	const editor = useEditor({
		extensions: [
			StarterKit,
			Mention.configure({
				renderLabel: (props) => {
					try {
						const prefixMap: { [type: string]: string } = {
							response: 'Response: ',
							enrichment: 'Property: ',
						}

						if (!useDrp) {
							// Alternate hack: check if this is an "Event type" dynamic field this way:
							// if (!props.node.attrs.id.includes('{')) {

							// Legacy backwards compatibility
							const option = suggestionOptions?.find(
								(o) =>
									o.value === props.node.attrs.id ||
									o.value.includes(props.node.attrs.id),
							)

							if (option) {
								return `{{ ${prefixMap[option.type] || ''}${option.label} }}`
							} else {
								return 'Unknown reference'
							}
						}

						const id = JSON.parse(props.node.attrs.id.replaceAll(';quot', '"'))

						const { refNodeId, variable, value } = id

						if (!refNodeId && !variable && !value) {
							return '{{ Click to pick data }}'
						}

						if (id.label) {
							return `{{ ${id.label} }}`
						}

						if (variable.includes('.responses.')) {
							const { parameterAttribute } = getParameterInfoCallback(id, null)
							return `{{ ${parameterAttribute?.name || variable} }}`
						}

						// Legacy backwards compatibility
						const option = suggestionOptions?.find(
							(o) =>
								o.value === props.node.attrs.id ||
								o.value.includes(props.node.attrs.id),
						)

						if (option) {
							return `{{ ${prefixMap[option.type] || ''}${option.label} }}`
						} else {
							return 'Unknown reference'
						}
					} catch (error) {
						return 'Unknown reference'
					}
				},
				HTMLAttributes: {
					class: 'mention',
				},
				suggestion: suggestionOptions
					? suggestion(suggestionOptions, useDrp || false)
					: undefined,
			}),
		],
		content: bodyText,
		editorProps: {
			attributes: {
				// this is needed to reset prosemirror's focus css
				class: 't-area',
			},
			handleDOMEvents: {
				mousedown: () => {
					isSelectingReference.current = true
					return true
				},
				mouseup: () => {
					isSelectingReference.current = false
					return true
				},
				mouseover: (view: any, event: any) => {
					if (document.getElementById('edit-hyperlink-bar')) {
						tip.remove()
						return true
					}
					if (isSelectingReference.current) {
						return true
					}
					//@ts-ignore
					const href = event?.path?.find((item) => item.href)?.href
					if (href) {
						tipLink.textContent = `${href}`
						tip.onclick = () => {
							setIsEditingLink(true)
							window.open(href)
						}

						const box = event.path[1]
						const actualNodeDomRect =
							event.path[0].getBoundingClientRect() as DOMRect
						const boxDomRect = event.path[1].getBoundingClientRect() as DOMRect
						const parentNode = view.dom.parentNode

						if (!parentNode) return true

						tip.style.top = box.offsetTop + 'px'

						tip.style.left = `${actualNodeDomRect.left - boxDomRect.x}px`
						// tip.style.top = `${actualNodeDomRect.bottom - boxDomRect.y + 48}px`;
						tip.style.transform = `translateX(calc(${
							(actualNodeDomRect.right - actualNodeDomRect.left) / 1.5
						}px - 50%))`

						tip.style.opacity = '0%'
						parentNode.appendChild(tip)
						tipBox.style.marginTop = `${
							(Math.round((tipBox.clientHeight - 33.5) / 19.5) - 1) * -19.5 +
							(actualNodeDomRect.top - boxDomRect.top - 8)
						}px`
						tip.style.opacity = '100%'

						return true
					}

					if (!href && !insideTooltipReference.current) {
						tip.remove()
					}

					return true
				},
			},
		},
		onUpdate: ({ editor }) => {
			onChange(editor?.getHTML() || '')
		},
	})

	useEffect(() => {
		if (document.getElementById('edit-hyperlink-bar')) {
			const gottenTip = document.getElementById('_tip')
			if (gottenTip) {
				/* gottenTip.style.opacity = "0%"; */
				gottenTip.remove()
			}
		}
	}, [editor?.state.selection])

	useEffect(() => {
		setTimeout(() => {
			inputRef.current?.focus()
		}, 100)
	}, [isEditingLink])

	useEffect(() => {
		// This is to notify components outside the editor that something
		// has changed in the text and preserve the cursor position.
		if (!editor) return
		const { from, to } = editor.state.selection
		editor.commands.setContent(bodyText, true, {
			preserveWhitespace: 'full',
		})
		editor.commands.setTextSelection({ from, to })
	}, [hasChanged, bodyText])

	const tip = document.createElement('div')
	tip.id = '_tip'
	tip.style.position = 'absolute'
	tip.style.backgroundColor = 'transparent'
	tip.style.paddingTop = '8px'
	tip.style.boxSizing = 'border-box'
	tip.style.maxWidth = '320px'
	tip.style.fontSize = '13px'

	const tipBox = document.createElement('div')
	tipBox.style.paddingLeft = '16px'
	tipBox.style.paddingRight = '16px'
	tipBox.style.paddingTop = '6px'
	tipBox.style.paddingBottom = '8px'
	tipBox.style.marginBottom = '8px'
	tipBox.style.borderRadius = '4px'
	tipBox.style.cursor = 'pointer'
	tipBox.style.boxSizing = 'border-box'

	const tipLink = document.createElement('a')
	tipLink.style.textDecoration = 'underline'

	tipBox.appendChild(tipLink)
	tip.appendChild(tipBox)

	if (colorMode === 'light') {
		tipBox.style.background = '#2b2b2c'
		tipLink.style.color = '#EDEEF0'
	} else {
		tipBox.style.background = '#F0F0F0'
		tipLink.style.color = '#222B48'
	}
	tip.style.zIndex = '100'

	tip.onmouseenter = () => {
		insideTooltipReference.current = true
	}
	tip.onmouseleave = () => {
		insideTooltipReference.current = false
	}

	const [selectedMentionSpan, setSelectedMentionSpan] =
		useState<Element | null>(null)

	const onClick: MouseEventHandler<HTMLDivElement> = (event) => {
		const eventX = event.clientX
		const eventY = event.clientY
		const elementsBelowCursor = document.elementsFromPoint(eventX, eventY)

		const mentionSpan = elementsBelowCursor.find(
			(el) =>
				el.tagName === 'SPAN' &&
				el.attributes.getNamedItem('data-type')?.value === 'mention',
		)

		if (useDrp) {
			if (mentionSpan) {
				setSelectedMentionSpan(mentionSpan)
			} else {
				setSelectedMentionSpan(null)
			}
		}
	}

	return (
		<Flex
			w="100%"
			direction="column"
			borderWidth="medium"
			borderColor={ColorTokens.border_primary}
			borderRadius="lg"
			bg={ColorTokens.white}
			boxShadow={focused ? 'focus' : 'none'}
			css={{
				a: {
					color: hyperlinkColor,
					cursor: 'pointer',
				},
			}}
			position="relative"
			overflow="visible"
			onClick={onClick}
			{...props}
		>
			{editor && selectedMentionSpan && (
				<Flex
					pos="fixed"
					zIndex={99999}
					top={selectedMentionSpan.getBoundingClientRect().y + 24}
					left={selectedMentionSpan.getBoundingClientRect().x}
					onClick={(e) => {
						e.stopPropagation()
					}}
				>
					<DataReferencePicker
						getInitialOptions={getInitialDrpOptions}
						initialOption={null}
						onSelect={(op) => {
							const { label } = op
							const spanString = selectedMentionSpan.outerHTML.replace(
								' contenteditable="false"',
								'',
							)

							const opValue = JSON.stringify(op).replaceAll('"', '&quot;')

							const newValue = editor
								.getHTML()
								.replace(
									spanString,
									`<span data-type="mention" class="mention" data-id="${opValue}">{{ ${label} }}</span>`,
								)

							setSelectedMentionSpan(null)
							onChange(newValue)
							if (onBlur) {
								onBlur(newValue)
							}
						}}
					/>
				</Flex>
			)}
			{editor && !disableEditingBar && (
				<EditingBar
					disabledEditingBarButtons={disabledEditingBarButtons}
					editor={editor}
				/>
			)}
			<EditorContent
				editor={editor}
				style={{
					background: ColorTokens.white,
					padding: '8px 16px',
					height: '100%',
					minHeight: minH ? minH : '240px',
					borderRadius: '0px 0px 8px 8px',
					outlineWidth: 0,
					outlineColor: 'rgba(118,120,237,0.85)',
					outlineStyle: 'solid',
					color: textColor,
					fontSize: '14px',
					fontWeight: 500,
					boxShadow: 'none',
					overflow: 'scroll',
					overflowX: 'hidden',
					cursor: 'text',
					...editorCss,
				}}
				className={editorClassName}
				value="1234"
				onClick={() => {
					if (!editor?.view) return
					// TODO: Figure out what this does or breaks.

					// setFocused(true)
					// const endPos = editor.view.state.doc.content.size
					// const selection = TextSelection.near(
					// 	editor.view.state.doc.resolve(endPos ?? 0),
					// )
					// editor.view.dispatch(
					// 	editor.view.state.tr.setSelection(selection).scrollIntoView(),
					// )
					// editor.view.focus()
				}}
				onFocus={() => setFocused(true)}
				onBlur={() => {
					setFocused(false)
					if (onBlur) {
						onBlur(editor?.getHTML() || '')
					}
				}}
				onKeyDown={(e) => {
					if (e.metaKey && e.key === 'Enter' && onClickEnter) {
						onClickEnter(editor?.getHTML() || '')
						setFocused(false)
						editor?.view.dom.blur()
					}
					if (e.key === 'Backspace') {
						dispatch(userActions.setIsRichTextEditorBackspacing(true))
					} else {
						dispatch(userActions.setIsRichTextEditorBackspacing(false))
					}
				}}
			/>
			{showSendButton && (
				<Flex
					boxSize={5}
					position="absolute"
					zIndex={9}
					bottom={3}
					right={3}
					justify="center"
					align="center"
					onClick={() => onClickEnter && onClickEnter(editor?.getHTML() || '')}
					_hover={{ cursor: 'pointer' }}
				>
					<GetIcon icon={Icons.send} color={COLORS.background[4]} />
				</Flex>
			)}
		</Flex>
	)
}
