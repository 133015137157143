import {
	ApolloEnrich,
	ApolloStartSequence,
	DefaultAddTag,
	DefaultDisplayScheduler,
	DefaultRedirectToUrl,
	DefaultRoute,
	DefaultTimeDelay,
	Harmonic,
	HTTPPostRequest,
	HubSpotCreateRecord,
	HubSpotMatchRecord,
	HubSpotMergeRecord,
	IfElse,
	OutreachStartSequence,
	SalesforceCreateRecord,
	SalesforceMatchRecord,
	SalesforceMergeRecord,
	SalesloftStartCadence,
	SendToLoops,
	SlackSendMessage,
} from '../components/side-panel/panel-content/step-details/integrations'
import { AiPrompt } from '../components/side-panel/panel-content/step-details/integrations/aiAction/AiPrompt'
import { AmplemarketEnrich } from '../components/side-panel/panel-content/step-details/integrations/amplemarket/AmplemarketEnrich'
import { AmplemarketStartInboundSmartAction } from '../components/side-panel/panel-content/step-details/integrations/amplemarket/AmplemarketStartInboundSmartAction'
import { ClearbitEnrich } from '../components/side-panel/panel-content/step-details/integrations/clearbit/ClearbitEnrich'
import { HubSpotCreateEngagement } from '../components/side-panel/panel-content/step-details/integrations/hubspot/HubSpotCreateEngagement'
import { SalesforceConvertLead } from '../components/side-panel/panel-content/step-details/integrations/salesforce/SalesforceConvertLead'
import { ZoomAddToWebinar } from '../components/side-panel/panel-content/step-details/integrations/zoom/ZoomAddToWebinar'
import { WorkflowIntegrationIds as IntegrationsIds } from '../utils/mappings'

export const setStepDetails = (integrationId: IntegrationsIds) => {
	switch (integrationId) {
		case IntegrationsIds.defaultAddTag:
			return <DefaultAddTag />
		case IntegrationsIds.defaultRoute:
			return <DefaultRoute />
		case IntegrationsIds.defaultRedirectToUrl:
			return <DefaultRedirectToUrl />
		case IntegrationsIds.defaultDisplayScheduler:
			return <DefaultDisplayScheduler />
		case IntegrationsIds.defaultSendToWebhook:
			return <HTTPPostRequest />
		case IntegrationsIds.defaultWaitNode:
			return <DefaultTimeDelay />

		case IntegrationsIds.salesforceConvertLead:
			return <SalesforceConvertLead />
		case IntegrationsIds.salesforceCreateRecord:
			return <SalesforceCreateRecord />
		case IntegrationsIds.salesforceMatchRecord:
			return <SalesforceMatchRecord />
		case IntegrationsIds.salesforceMergeRecord:
			return <SalesforceMergeRecord />
		case IntegrationsIds.slackSendMessage:
			return <SlackSendMessage />

		case IntegrationsIds.hubspotCreateRecord:
			return <HubSpotCreateRecord />
		case IntegrationsIds.hubspotMergeRecord:
			return <HubSpotMergeRecord />
		case IntegrationsIds.hubspotMatchRecord:
			return <HubSpotMatchRecord />
		case IntegrationsIds.hubspotCreateEngagement:
			return <HubSpotCreateEngagement />
		case IntegrationsIds.outreachStartSequence:
			return <OutreachStartSequence />
		case IntegrationsIds.salesloftStartCadence:
			return <SalesloftStartCadence />

		case IntegrationsIds.ifElse:
			return <IfElse />
		case IntegrationsIds.loops:
			return <SendToLoops />
		case IntegrationsIds.harmonic:
			return <Harmonic />
		case IntegrationsIds.apolloEnrich:
			return <ApolloEnrich />
		case IntegrationsIds.clearbitEnrich:
			return <ClearbitEnrich />
		case IntegrationsIds.apolloStartSequence:
			return <ApolloStartSequence />

		case IntegrationsIds.zoomAddToWebinar:
			return <ZoomAddToWebinar />

		case IntegrationsIds.aiPrompt:
			return <AiPrompt />

		case IntegrationsIds.amplemarketEnrich:
			return <AmplemarketEnrich />

		case IntegrationsIds.amplemarketStartInboundSmartAction:
			return <AmplemarketStartInboundSmartAction />
		default:
			return null
	}
}
