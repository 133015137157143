import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RowSelectionState } from '@tanstack/react-table'

import { TeamMember } from '@/app/user/api/members/type'

export interface WorkspaceSettingsState {
	selectedDeleteUsers?: TeamMember[] | null
	selectUserForEdit?: TeamMember | null
	userRowSelection: RowSelectionState
}

export const initialState: WorkspaceSettingsState = {
	selectedDeleteUsers: null,
	selectUserForEdit: null,
	userRowSelection: {},
}

export const workspaceSettingsSlice = createSlice({
	name: 'WorkflowSettings',
	initialState,
	reducers: {
		setSelectedDeleteUsers: (
			state,
			{ payload }: PayloadAction<TeamMember[] | null>,
		) => {
			state.selectedDeleteUsers = payload
		},
		selectUserForEdit: (
			state,
			{ payload }: PayloadAction<TeamMember | null>,
		) => {
			state.selectUserForEdit = payload
		},
		setUserRowSelection: (
			state,
			{ payload }: PayloadAction<RowSelectionState>,
		) => {
			state.userRowSelection = payload
		},
	},
})

export const workspaceSettingsActions = workspaceSettingsSlice.actions
export const workspaceSettingsReducer = workspaceSettingsSlice.reducer
