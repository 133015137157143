import { useCallback } from 'react'
import { Edge } from 'reactflow'

import { CanvasNodes } from '@/modules/workflow/types/nodes'

import { createEdge } from '../utils/createEdge'
import { FlowStart } from '../utils/FlowStart'

export const useReconstructedEdges = () => {
	return useCallback((nodesObj: CanvasNodes): Edge[] => {
		const nodes = [FlowStart, ...Object.values(nodesObj)]
		const recreatedEdges: Edge[] = []

		nodes.forEach((parent) => {
			const { stepType, integrationId, children } = parent.data
			children.forEach((childId) => {
				nodesObj[childId]?.data.parentIds
					.filter((id) => id.includes(parent.id))
					.forEach((fullParent) => {
						const includesHandle = fullParent.includes('&')
						const parentId = fullParent.split('&')[0]
						const sourceHandle = includesHandle ? fullParent : undefined
						const edge = createEdge({
							parentId,
							childId,
							stepType,
							sourceHandle,
							integrationId,
						})
						if (
							!recreatedEdges.find(
								(recreatedEdge) => recreatedEdge.id === edge.id,
							)
						) {
							recreatedEdges.push({ ...edge, style: undefined })
						}
					})
			})
		})
		return recreatedEdges
	}, [])
}
