import { Center, Image } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { GetIcon } from 'ui'

import { FormType } from '@/modules/forms/types'
import { Borders } from '@/styles/borders'

import { getWorkflowLogo } from '../utils/getWorkflowLogo'

type Props = {
	type?: FormType | null
}

export const WorkflowLogo = ({ type }: Props) => {
	const { logo, icon } = getWorkflowLogo(type)

	return (
		<Center
			w="44px"
			h="44px"
			flexShrink={0}
			bg={COLORS.background[12]}
			borderRadius="lg"
			border={Borders.primary}
			color={COLORS.background[4]}
		>
			{logo && <Image src={logo} w="24px" />}
			{icon && <GetIcon icon={icon} boxSize="16px" />}
		</Center>
	)
}
