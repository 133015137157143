import { Flex } from '@chakra-ui/react'
import { Icons } from '@ds/Icons'
import { cloneDeep } from 'lodash'
import { isEqual } from 'lodash'
import React from 'react'

import { QuestionTable } from '@/modules/forms/types/questions'
import { TestSubmissionResponse } from '@/modules/submission/types'

import { PanelInputLabel } from '../../panel-variants/PanelInputLabel'
import { TestQuestionInput } from './TestQuestionInput'

interface TestQuestionsProps {
	formData: Record<string, string | string[]>
	questions: QuestionTable[]
	responses?: TestSubmissionResponse[]
}

export const TestQuestions = React.memo(
	({ formData, questions, responses }: TestQuestionsProps) => {
		return (
			<Flex direction="column">
				<PanelInputLabel
					label="Form Questions"
					leftIcon={Icons.input}
					ml={-0.5}
				/>
				<Flex direction="column" gap={4} mt={2}>
					{cloneDeep(questions)
						?.sort(
							(a, b) =>
								a.page_number - b.page_number ||
								a.question_order - b.question_order,
						)
						.map((question) => {
							const formDataValue = formData[question.id]

							return (
								<TestQuestionInput
									key={question.id}
									initialValue={formDataValue ? [formDataValue].flat() : []}
									question={question}
									response={responses?.find(
										(response) => response.question_id === question.id,
									)}
								/>
							)
						})}
				</Flex>
			</Flex>
		)
	},
	(prevProps, nextProps) => {
		return (
			isEqual(prevProps.formData, nextProps.formData) &&
			isEqual(prevProps.questions, nextProps.questions) &&
			isEqual(prevProps.responses, nextProps.responses)
		)
	},
)
