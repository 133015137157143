import 'reactflow/dist/style.css'
import '@/modules/workflow/styles/canvas.css'

import { Flex } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { cloneDeep } from 'lodash'
import { nanoid } from 'nanoid/non-secure'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMount, useUpdateEffect } from 'react-use'
import ReactFlow, {
	Background,
	BackgroundVariant,
	Connection,
	Edge,
	ReactFlowInstance,
	ReactFlowProvider,
	SelectionMode,
	useEdgesState,
	useNodesState,
	useReactFlow,
	useStoreApi,
	useViewport,
} from 'reactflow'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { useUpdateFormMutation } from '@/modules/forms/api'
import { useGetQuestionsByFormIdQuery } from '@/modules/forms/api/questions'
import { FormType } from '@/modules/forms/types'
import { CanvasControlBar } from '@/modules/workflow/components/canvas/controls/CanvasControlBar'
import { WorkflowActionCanvasNode } from '@/modules/workflow/components/canvas/node-types/WorkflowActionCanvasNode'
import { WorkflowLogicCanvasNode } from '@/modules/workflow/components/canvas/node-types/WorkflowLogicCanvasNode'
import { WorkflowTriggerCanvasNode } from '@/modules/workflow/components/canvas/node-types/WorkflowTriggerCanvasNode'
import {
	useReconstructedEdges,
	useUpdateWorkflowNode,
	useUpdateWorkflowNodes,
} from '@/modules/workflow/hooks'
import {
	useCanvasNodes,
	useCanvasWorkflowLog,
	useDroppableBranch,
	useSelectedNode,
	useSelectedWorkflow,
	useWorkflowReadOnly,
} from '@/modules/workflow/hooks/workflow'
import { workflowsActions } from '@/modules/workflow/slice'
import {
	selectCanvasNodeMenuOpenId,
	selectClickToConnectInteraction,
	selectIntegrationListItemToConnect,
	selectSelectedWorkflowId,
	selectSyncWorkflow,
} from '@/modules/workflow/slice/selectors'
import { LogicStepDetails, ResultOrElse } from '@/modules/workflow/types/logic'
import { NavigationId } from '@/modules/workflow/types/navigation'
import {
	CanvasNode,
	DnDNodeData,
	RFNodeType,
	StepType,
} from '@/modules/workflow/types/nodes'
import { Salesforce_MatchRecord } from '@/modules/workflow/types/salesforce'
import { createEdge } from '@/modules/workflow/utils/createEdge'
import {
	createLogicStepDetails,
	getAllAncestors,
} from '@/modules/workflow/utils/graph-operations'
import { WorkflowIntegrationIds as Integrations } from '@/modules/workflow/utils/mappings'
import {
	WORKFLOW_NODE_DROPPABLE_AREA_ID,
	WORKFLOW_NODE_PLUS_BUTTON_ID,
	WORKFLOW_NODE_PLUS_BUTTON_SEPARATOR,
} from '@/utils/constants'

import { useGetUpstreamNodes } from '../../hooks/useGetUpstreamNodes'
import { Default_DisplayScheduler } from '../../types/actions'
import { MatchRecord } from '../../types/matching'
import { CustomEdge } from './edges/CustomEdge'

const PRO_OPTIONS = {
	account: 'paid-pro',
	hideAttribution: true,
}

const DROPPABLE_AREA_PADDING = 75

const viewPortOptions = { zoom: 0.8, x: 250, y: 250 }

const Flow = () => {
	const store = useStoreApi()
	const storeActions = store.getState()
	const internals = storeActions.nodeInternals
	const userSelectionRect = storeActions.userSelectionRect
	const RFDeselectAll = () => storeActions.unselectNodesAndEdges()
	const RFReset = () => storeActions.resetSelectedElements()
	const RFSelect = (nodeIds: string[]) => storeActions.addSelectedNodes(nodeIds)
	const createEdges = useReconstructedEdges()
	const { setViewport, zoomTo } = useReactFlow()
	const viewport = useViewport()
	const reactFlowWrapper = useRef<HTMLDivElement>(null)
	const [triggerUpdate, setTriggerUpdate] = useState(false)
	const [panOnDrag, setPanOnDrag] = useState(true)

	const [mutateWorkflow] = useUpdateFormMutation()

	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow

	const droppableBranch = useDroppableBranch()
	const canvasNodeMenuId = useAppSelector(selectCanvasNodeMenuOpenId)

	const clickToConnect = useAppSelector(selectClickToConnectInteraction)
	const integrationListItemToConnect = useAppSelector(
		selectIntegrationListItemToConnect,
	)

	const { getUpstreamNodes } = useGetUpstreamNodes()

	// This is to load questions as soon as possible to avoid lack of question data in nodes where it's relevant
	const selectedWorkflowId = useAppSelector(selectSelectedWorkflowId)
	useGetQuestionsByFormIdQuery(String(selectedWorkflowId), {
		skip: !selectedWorkflowId,
	})

	const nodeTypes = useMemo(
		() => ({
			flowStart: WorkflowTriggerCanvasNode,
			actionNode: WorkflowActionCanvasNode,
			logicNode: WorkflowLogicCanvasNode,
		}),
		[],
	)
	const edgeTypes = useMemo(
		() => ({
			custom: CustomEdge,
		}),
		[],
	)

	const dispatch = useAppDispatch()
	const syncWorkflow = useAppSelector(selectSyncWorkflow)

	const isReadOnly = useWorkflowReadOnly()
	const updateNode = useUpdateWorkflowNode()
	const updateWorkflow = useUpdateWorkflowNodes()
	const { workflowLog } = useCanvasWorkflowLog()

	const canvasNodes = useCanvasNodes()?.canvasNodes
	/** Nodes stored in DB  */
	const storedNodes = useMemo(() => Object.values(canvasNodes), [canvasNodes])
	/** Nodes locally */
	const [nodes, setNodes, onNodesChange] = useNodesState(storedNodes)
	const [edges, setEdges, onEdgesChange] = useEdgesState(
		createEdges(canvasNodes),
	)

	// Handle styling of executed steps from a workflow log
	useEffect(() => {
		if (workflowLog) {
			setNodes((nds) =>
				nds.map((node) => {
					const executedStep = workflowLog.steps.find(
						(step) => step.key === node.id,
					)
					return {
						...node,
						data: {
							...node.data,
							executedStep,
						},
					}
				}),
			)

			setEdges((eds) =>
				eds.map((edge) => {
					const sourceStep = workflowLog.steps.find(
						(step) => step.key === edge.source,
					)
					const targetStep = workflowLog.steps.find(
						(step) => step.key === edge.target,
					)
					const wasExecuted = (edge.source === '0' || sourceStep) && targetStep

					return {
						...edge,
						animated: !!wasExecuted,
						style: {
							stroke: wasExecuted ? COLORS.green[7] : COLORS.background[9],
						},
					}
				}),
			)
		}
	}, [workflowLog])

	// Handle draggability of nodes when workflow is read only
	useEffect(() => {
		setNodes((nds) =>
			nds.map((node) => {
				return {
					...node,
					draggable: !isReadOnly,
				}
			}),
		)
	}, [isReadOnly])

	const selectedNode = useSelectedNode()

	/**
	 * This hook will sync local nodes when we make updates to db nodes from some other components like delete nodes, etc.
	 * Ideally, if there is a diff between storedNodes(db) and nodes(local), we should update local one.
	 * But very slippery slope to calculate diff between local and db nodes.
	 * Lodash isEqual is not working as expected as well.
	 */
	useUpdateEffect(() => {
		if (syncWorkflow) {
			setNodes(storedNodes)
			setEdges(createEdges(canvasNodes))
			dispatch(workflowsActions.setSyncWorkflow(false))
		}
	}, [syncWorkflow])

	useUpdateEffect(() => {
		if (clickToConnect) {
			const droppableAreaEl = document.getElementById(
				`${WORKFLOW_NODE_DROPPABLE_AREA_ID}_CENTER`,
			)
			droppableAreaEl?.classList.add('droppable-area-hover')
		}

		if (!isReadOnly) {
			connectNodeToDroppableBranch()
			updateWorkflow(nodes, true)
		}
		dispatch(workflowsActions.setCurrentCanvasNodes(canvasNodes))
	}, [triggerUpdate, droppableBranch])

	useUpdateEffect(() => {
		if (clickToConnect && integrationListItemToConnect) {
			handleConnectIntegrationOnClick()
		}
	}, [integrationListItemToConnect])

	useUpdateEffect(() => {
		if (selectedNode) {
			RFSelect([selectedNode.data.id])
		}
	}, [selectedNode])

	useMount(() => {
		if (
			!selectedWorkflow?.type ||
			selectedWorkflow?.type === FormType.draft_trigger
		) {
			dispatch(
				workflowsActions.setSelectedSidePanelNavigationId(
					NavigationId.AddObjects,
				),
			)
		} else {
			dispatch(
				workflowsActions.setSelectedSidePanelNavigationId(
					NavigationId.Overview,
				),
			)
		}
	})

	const onNodeDrag = (_, n: CanvasNode) => {
		if (!n) return

		dispatch(workflowsActions.setClickToConnectInteraction(false))

		const draggableNodeEl = document.querySelector(`[data-id="${n.id}"]`)
		const draggableNodeRect = draggableNodeEl?.getBoundingClientRect()

		const droppableAreaEl = document.getElementById(
			`${WORKFLOW_NODE_DROPPABLE_AREA_ID}_CENTER`,
		)
		const droppableAreaRect = droppableAreaEl?.getBoundingClientRect()

		if (
			isOverlapping({
				draggableRect: draggableNodeRect,
				droppableRect: droppableAreaRect,
			})
		) {
			droppableAreaEl?.classList.add('droppable-area-hover')
		} else {
			droppableAreaEl?.classList.remove('droppable-area-hover')
		}

		const plusButtonDOMNodes = Array.from(
			document.querySelectorAll(`[id^="${WORKFLOW_NODE_PLUS_BUTTON_ID}"]`),
		).map((el) => ({
			branchId: el.id?.split(WORKFLOW_NODE_PLUS_BUTTON_SEPARATOR)?.[1],
			rect: el.getBoundingClientRect(),
		}))

		const overLappingPlusButton = plusButtonDOMNodes.find(
			({ branchId, rect }) =>
				isOverlapping({
					draggableRect: draggableNodeRect,
					droppableRect: {
						...rect,
						top: rect.top - DROPPABLE_AREA_PADDING,
						bottom: rect.bottom + DROPPABLE_AREA_PADDING,
						right: rect.right + DROPPABLE_AREA_PADDING,
						left: rect.left - DROPPABLE_AREA_PADDING,
					},
				}) && n.id !== branchId.split('&')[0],
		)

		setTimeout(() => {
			if (
				overLappingPlusButton &&
				isBranchConnectable({
					nodeId: n.id,
					branchId: overLappingPlusButton.branchId,
				})
			) {
				dispatch(
					workflowsActions.updateDroppableBranch(
						overLappingPlusButton.branchId,
					),
				)
			} else {
				dispatch(workflowsActions.updateDroppableBranch(null))
			}
		}, 0)
	}

	const onNodeDragStop = (event, n: CanvasNode, ns: CanvasNode[]) => {
		if (!n) return
		const fixing = ns.map((n) => {
			n.dragging = false
			return n
		})

		setNodes((prev) => {
			const ids = ns.map((n) => n.id)
			const otherNodes = prev.filter((n) => !ids.includes(n.id))
			return otherNodes.concat(fixing)
		})

		const eventX = event.clientX
		const eventY = event.clientY
		const elementsBelowCursor = document.elementsFromPoint(eventX, eventY)

		const draggableNodeEl = document.querySelector(`[data-id="${n.id}"]`)
		const draggableNodeRect = draggableNodeEl?.getBoundingClientRect()

		const droppableAreaEl = document.getElementById(
			`${WORKFLOW_NODE_DROPPABLE_AREA_ID}_CENTER`,
		)
		const droppableAreaRect = droppableAreaEl?.getBoundingClientRect()

		if (
			droppableBranch &&
			n.id !== droppableBranch &&
			isBranchConnectable({ nodeId: n.id, branchId: droppableBranch }) &&
			(isOverlapping({
				draggableRect: draggableNodeRect,
				droppableRect: droppableAreaRect,
			}) ||
				clickToConnect)
		) {
			const valid = isDropValid(n.id)
			if (!valid) return

			const { x, y } = getDroppableBranchPosition()
			updateNode({
				...n,
				position: {
					x,
					y,
				},
				data: {
					...n.data,
					parentIds: [...n.data.parentIds, droppableBranch],
					x,
					y,
				},
			})

			if (clickToConnect) {
				dispatch(workflowsActions.setClickToConnectInteraction(false))
			}
		} else if (
			elementsBelowCursor.find((e) =>
				e.id.includes(WORKFLOW_NODE_PLUS_BUTTON_ID),
			)
		) {
			const branchId = elementsBelowCursor
				.find((e) => e.id.includes(WORKFLOW_NODE_PLUS_BUTTON_ID))
				?.id?.split(WORKFLOW_NODE_PLUS_BUTTON_SEPARATOR)?.[1]

			if (branchId) {
				dispatch(workflowsActions.updateDroppableBranch(branchId))
				dispatch(workflowsActions.setClickToConnectInteraction(true))
			}
		} else {
			dispatch(workflowsActions.updateDroppableBranch(null))
			dispatch(workflowsActions.setClickToConnectInteraction(false))
		}

		dispatch(workflowsActions.updateSelectedNode(n.data.id))
		dispatch(workflowsActions.setIsSidePanelContentOpen(true))
		dispatch(
			workflowsActions.setSelectedSidePanelNavigationId(
				NavigationId.AddObjects,
			),
		)

		setTimeout(() => {
			setTriggerUpdate((prev) => !prev)
		}, 0)
	}

	const onSelectionDragStop = (_, ns: CanvasNode[]) => {
		const fixing = ns.map((n) => {
			n.dragging = false
			return n
		})
		setNodes((prev) => {
			const ids = ns.map((n) => n.id)
			const otherNodes = prev.filter((n) => !ids.includes(n.id))
			return otherNodes.concat(fixing)
		})
		setTriggerUpdate((prev) => !prev)
	}

	const onDragOver = useCallback((event) => {
		event.preventDefault()
		event.dataTransfer.dropEffect = 'move'

		const eventX = event.clientX
		const eventY = event.clientY
		const cursorRect = {
			top: eventY - 30,
			bottom: eventY + 30,
			left: eventX - 30,
			right: eventX + 30,
		}

		const plusButtonDOMNodes = Array.from(
			document.querySelectorAll(`[id^="${WORKFLOW_NODE_PLUS_BUTTON_ID}"]`),
		).map((el) => ({
			branchId: el.id?.split(WORKFLOW_NODE_PLUS_BUTTON_SEPARATOR)?.[1],
			rect: el.getBoundingClientRect(),
		}))

		const overLappingPlusButton = plusButtonDOMNodes.find(({ rect }) =>
			isOverlapping({
				draggableRect: cursorRect as DOMRect,
				droppableRect: {
					...rect,
					top: rect.top - DROPPABLE_AREA_PADDING,
					bottom: rect.bottom + DROPPABLE_AREA_PADDING,
					right: rect.right + DROPPABLE_AREA_PADDING,
					left: rect.left - DROPPABLE_AREA_PADDING,
				},
			}),
		)

		setTimeout(() => {
			if (overLappingPlusButton) {
				dispatch(
					workflowsActions.updateDroppableBranch(
						overLappingPlusButton.branchId,
					),
				)

				const droppableAreaEl = document.getElementById(
					`${WORKFLOW_NODE_DROPPABLE_AREA_ID}_CENTER`,
				)
				droppableAreaEl?.classList.add('droppable-area-hover')
			} else {
				dispatch(workflowsActions.updateDroppableBranch(null))
			}
		}, 0)
	}, [])

	const [reactFlowInstance, setReactFlowInstance] =
		useState<ReactFlowInstance | null>(null)

	const onDrop = useCallback(
		(event) => {
			if (isReadOnly) return
			event.preventDefault()
			const flowRef = reactFlowWrapper.current
			if (!flowRef) return
			const reactFlowBounds = flowRef.getBoundingClientRect()
			const passedData = event.dataTransfer.getData('application/reactflow')
			const data: DnDNodeData = JSON.parse(passedData)

			const { nodeType, integrationId, stepType, editableName } = data

			if (typeof nodeType === 'undefined' || !nodeType) {
				return
			}

			if (!reactFlowInstance) return

			const position = reactFlowInstance.project({
				x: event.clientX - reactFlowBounds.left - 120,
				y: event.clientY - reactFlowBounds.top - 28,
			})

			let x = position.x
			let y = position.y

			let stepDetails:
				| LogicStepDetails
				| Default_DisplayScheduler
				| MatchRecord
				| null = null
			if (nodeType === RFNodeType.logicNode) {
				stepDetails = createLogicStepDetails(integrationId)
			}

			let parentIds: string[] = []

			if (droppableBranch) {
				const { x: newX, y: newY } = getDroppableBranchPosition()
				x = newX
				y = newY
				parentIds = [droppableBranch]
			}

			const id = nanoid(8)
			const newNode: CanvasNode = {
				id,
				type: nodeType,
				position: {
					x,
					y,
				},
				connectable: true,
				draggable: true,
				data: {
					id,
					integrationId,
					editableName,
					description: '',
					stepType,
					children: [],
					parentIds,
					stepDetails,
					nextStep: null,
					x,
					y,
				},
			}

			setNodes((nds) => nds.concat(newNode))
			setTriggerUpdate((prev) => !prev)
			dispatch(workflowsActions.updateSelectedNode(newNode.data.id))
		},
		[reactFlowInstance, droppableBranch],
	)

	const handleTransform = useCallback(
		(position) => {
			setViewport(
				{ x: -position.x + 300, y: -position.y + 300, zoom: 1 },
				{ duration: 550 },
			)
		},
		[setViewport],
	)

	const onConnect = (conn: Connection) => {
		if (!conn || !conn.source || !conn.target || conn.target === conn.source)
			return

		const { source, target, sourceHandle } = conn
		const ids: string[] = [source, target]
		const parentNodeIndex = nodes.findIndex((n) => n.id === source)
		const childNodeIndex = nodes.findIndex((n) => n.id === target) // is the target node actually in the nodes?
		const stepType = canvasNodes[source].data.stepType
		const integrationId = canvasNodes[source].data.integrationId

		const e = createEdge({
			parentId: sourceHandle ? sourceHandle.split('&')[0] : source,
			childId: target,
			sourceHandle,
			stepType,
			integrationId,
		})

		if (
			parentNodeIndex < 0 ||
			childNodeIndex < 0 ||
			(canvasNodes[source].data.children.length > 0 &&
				canvasNodes[source].data.children.every((c) => canvasNodes[c]) &&
				stepType != StepType.Logic) ||
			getAllAncestors(source, canvasNodes).includes(target)
		) {
			return
		}

		if (stepType == StepType.Logic && sourceHandle) {
			const handle = sourceHandle.split('&')[1]
			const branch = handle === 'true'
			const index = branch ? 0 : 1
			const whichChild: ResultOrElse = branch ? 'resultChildId' : 'elseChildId'
			setNodes((prev) => {
				const otherNodes = nodes.filter((n) => !ids.includes(n.id))
				const parent: CanvasNode = structuredClone(prev[parentNodeIndex])
				const child = structuredClone(prev[childNodeIndex])
				parent.data.children.splice(index, 0, target)
				if (integrationId == Integrations.ifElse) {
					;(parent.data.stepDetails as LogicStepDetails).branches[0][
						whichChild
					] = target
				}
				if (
					integrationId == Integrations.defaultDisplayScheduler ||
					integrationId == Integrations.salesforceMatchRecord ||
					integrationId == Integrations.hubspotMatchRecord
				) {
					// for type checker, it will work also on type Default_DisplayScheduler
					;(parent.data.stepDetails as Salesforce_MatchRecord)[whichChild] =
						target
				}
				child.data.parentIds.push(sourceHandle)
				return otherNodes.concat([parent, child])
			})
			setEdges((prev) => prev.concat(e))
			setTriggerUpdate((prev) => !prev)
			return
		}

		setNodes((prev) => {
			const otherNodes = nodes.filter((n) => !ids.includes(n.id))
			if (!conn.target || !conn.source) return otherNodes
			const parent = structuredClone(prev[parentNodeIndex])
			const child = structuredClone(prev[childNodeIndex])
			parent.data.children = parent.data.children.filter((c) => canvasNodes[c])
			parent.data.children.push(conn.target)
			parent.data.nextStep = conn.target
			child.data.parentIds.push(conn.source)
			return otherNodes.concat([parent, child])
		})
		setEdges((prev) => prev.concat(e))
		setTriggerUpdate((prev) => !prev)
	}

	const onSelectionEnd = () => {
		dispatch(workflowsActions.updateSelectedNode(null))
		dispatch(workflowsActions.updateDroppableBranch(null))
		setTimeout(() => {
			const start = internals.get('0')
			const selectedNodes = Array.from(internals.values())
				.filter((n) => n.selected)
				.sort(
					(a, b) => a.position.x - b.position.x || a.position.y - b.position.y,
				)
			if (userSelectionRect && userSelectionRect.width < 5) return

			/** ids of nodes being selected */
			const ids = selectedNodes.map((n) => n.id)
			const parentIdsOfSelectedNodes = [
				...new Set(
					selectedNodes
						.map((n) => n.data.parentIds.map((pId) => pId.split('&')[0]))
						.flat()
						.filter((id) => !id.includes('0')),
				),
			]

			const parentLogicNodes = Array.from(internals.values())
				.filter(
					(n) =>
						parentIdsOfSelectedNodes.includes(n.id) &&
						n.data.stepType === 'logic',
				)
				.map((n) => n.id)

			const selectionAreaCollection = document.getElementsByClassName(
				'react-flow__nodesselection-rect',
			)

			const selectionArea = selectionAreaCollection.item(0)
			if (selectionArea && userSelectionRect && start && !start.selected) {
				const popupArea = document.createElement('div')
				popupArea.id = 'alignment_popup_area'
				popupArea.style.height = '100%'
				popupArea.style.width = '100%'
				popupArea.style.position = 'relative'

				const alignmentPopup = document.createElement('div')
				alignmentPopup.style.position = 'absolute'
				alignmentPopup.style.boxShadow = '0px 1px 4px rgba(34, 43, 72, 0.08)'
				alignmentPopup.style.padding = '12px'
				alignmentPopup.style.background = '#fff'
				alignmentPopup.style.borderRadius = '12px'
				alignmentPopup.style.top = '-120px'
				alignmentPopup.style.left = '0px'
				alignmentPopup.style.fontWeight = '500'
				alignmentPopup.style.fontSize = '14px'
				alignmentPopup.style.width = '200px'
				alignmentPopup.style.cursor = 'pointer'
				alignmentPopup.style.border = '1px solid #DFE4F0'

				const list = document.createElement('ul')
				list.setAttribute('id', 'alignment_list')

				const alignOptions = [
					{
						label: 'Duplicate',
						action: () => {
							setNodes((prev) => {
								const otherNodes = [...prev]
								const newNodes = selectedNodes.map((n) => {
									const id = nanoid(8)
									const stepDetails = {
										...n.data.stepDetails,
										resultChildId: null,
										elseChildId: null,
									}

									// Check if branches exist and is an array
									if (
										n.data?.stepDetails?.branches &&
										Array.isArray(n.data.stepDetails.branches)
									) {
										stepDetails.branches = n.data.stepDetails.branches.map(
											(branch) => ({
												...branch,
												resultChildId: null,
												elseChildId: null,
											}),
										)
									}

									return {
										...n,
										id,
										data: {
											...n.data,
											id,
											nextStep: null,
											children: [],
											parentIds: [],
											stepDetails,
										},
										position: {
											x: n.position.x + 360,
											y: n.position.y,
										},
									}
								})
								return otherNodes.concat(newNodes)
							})
							setTriggerUpdate((prev) => !prev)
							RFDeselectAll()
							RFReset()
						},
					},
					...(selectedNodes.length < 2
						? []
						: [
								{
									label: 'Align Column',
									action: () => {
										setNodes((prev) => {
											const otherNodes = prev.filter((n) => !ids.includes(n.id))
											const aligned = selectedNodes.map((n, i) => ({
												...n,
												position: {
													x: selectedNodes[0].position.x,
													y: selectedNodes[i].position.y,
												},
											}))
											return otherNodes.concat(aligned)
										})
										setTriggerUpdate((prev) => !prev)
										RFDeselectAll()
										RFReset()
									},
								},
								{
									label: 'Align Row',
									action: () => {
										setNodes((prev) => {
											const otherNodes = prev.filter((n) => !ids.includes(n.id))
											const aligned = selectedNodes.map((n, i) => ({
												...n,
												position: {
													x: selectedNodes[i].position.x,
													y: selectedNodes[0].position.y,
												},
											}))
											return otherNodes.concat(aligned)
										})
										setTriggerUpdate((prev) => !prev)
										RFDeselectAll()
										RFReset()
									},
								},
							]),
					{
						label: 'Delete Selected',
						action: () => {
							setNodes((prev) => {
								const otherNodes = prev
									.filter((n) => !ids.includes(n.id) && Boolean(n?.data))
									.map((n) => {
										const data = cloneDeep(n.data)
										if (n.data.nextStep && ids.includes(n.data.nextStep)) {
											data.nextStep = null
										}
										data.children = n.data.children.filter(
											(c) => !ids.includes(c),
										)
										data.parentIds = n.data.parentIds.filter(
											// If a logic node is a parent
											(p) => !ids.includes(p.split('&')[0]),
										)

										if (parentLogicNodes.includes(n.id)) {
											const stepDetails = data.stepDetails as LogicStepDetails &
												MatchRecord &
												Default_DisplayScheduler

											if (stepDetails) {
												const branch = stepDetails.branches?.[0]

												if (branch) {
													const { resultChildId, elseChildId } = branch

													if (resultChildId && ids.includes(resultChildId)) {
														branch.resultChildId = null
													}

													if (elseChildId && ids.includes(elseChildId)) {
														branch.elseChildId = null
													}
												} else {
													const { resultChildId, elseChildId } = stepDetails

													if (resultChildId && ids.includes(resultChildId)) {
														stepDetails.resultChildId = null
													}

													if (elseChildId && ids.includes(elseChildId)) {
														stepDetails.elseChildId = null
													}
												}
											}
										}

										const m = { ...n, data }
										return m
									})
								return otherNodes
							})
							setTriggerUpdate((prev) => !prev)
							RFDeselectAll()
							RFReset()
						},
					},
				]
				alignOptions.forEach((opt) => {
					const item = document.createElement('div')
					item.textContent = opt.label
					item.style.paddingBottom = '6px'
					item.style.marginBottom = '6px'
					if (opt.label === 'Delete Selected') {
						item.style.color = '#D42C22'
						item.style.paddingBottom = '0px'
						item.style.marginBottom = '0px'
					}
					item.onclick = opt.action
					list.appendChild(item)
				})
				alignmentPopup.appendChild(list)
				popupArea.appendChild(alignmentPopup)

				selectionArea.appendChild(popupArea)
			}
		}, 75)
	}

	const onPaneClick = useCallback(() => {
		/** Close the node menu if it open */
		dispatch(workflowsActions.setCanvasNodeMenuOpenId(null))

		dispatch(workflowsActions.updateSelectedNode(null))
		dispatch(workflowsActions.updateDroppableBranch(null))
		dispatch(workflowsActions.setClickToConnectInteraction(false))

		const alignmentPopup = document.querySelector('#alignment_popup_area')
		if (alignmentPopup) {
			alignmentPopup.remove()
		}
		setTimeout(() => {
			dispatch(workflowsActions.updateSelectedNode(null))
		}, 0)

		RFDeselectAll()
		RFReset()
	}, [edges])

	const onNodeDoubleClick = useCallback(
		(e, { position, data: { stepType } }) => {
			if (stepType !== null) {
				handleTransform(position)
			}
		},
		[],
	)

	const onEdgeMouseLeave = useCallback(
		(_event, edge: Edge) => {
			setEdges((prev) =>
				prev.map((e) => {
					if (e.id === edge.id) {
						return {
							...e,
							data: {
								...e.data,
								isHovered: false,
							},
						}
					}
					return e
				}),
			)
		},
		[setEdges],
	)

	const onEdgeMouseEnter = useCallback(
		(_event, edge: Edge) => {
			setEdges((prev) =>
				prev.map((e) => {
					if (e.id === edge.id) {
						return {
							...e,
							data: {
								...e.data,
								isHovered: true,
							},
						}
					}
					return e
				}),
			)
		},
		[setEdges],
	)

	const onEdgeClick = useCallback(() => {
		setTriggerUpdate((prev) => !prev)
		dispatch(workflowsActions.updateSelectedNode(null))
	}, [dispatch])

	const onSelectionStart = useCallback(() => {
		RFDeselectAll()
		RFReset()
		setTimeout(() => {
			dispatch(workflowsActions.updateSelectedNode(null))
		}, 0)
	}, [])

	const onNodeClick = useCallback(
		(_, n: CanvasNode) => {
			dispatch(workflowsActions.updateSelectedNode(n.data.id))
			dispatch(workflowsActions.setIsSidePanelContentOpen(true))
			dispatch(
				workflowsActions.setSelectedSidePanelNavigationId(
					NavigationId.AddObjects,
				),
			)
			if (canvasNodeMenuId) {
				dispatch(workflowsActions.setCanvasNodeMenuOpenId(null))
			}
		},
		[dispatch, canvasNodeMenuId],
	)

	// New functions, don't fit in graph-operations
	const getDroppableBranchPosition = () => {
		let x = 0
		let y = 0
		if (droppableBranch && reactFlowInstance) {
			const [droppableBranchNodeId, droppableBranchType] =
				droppableBranch.split('&')

			const droppableBranchNode = reactFlowInstance
				.getNodes()
				.find((node) => node.id === droppableBranchNodeId)

			if (droppableBranchNode) {
				const droppableBranchNodeX = droppableBranchNode.position.x
				const droppableBranchNodeY = droppableBranchNode.position.y
				switch (droppableBranchType) {
					case undefined:
						x = droppableBranchNodeX
						y = droppableBranchNodeY + 180

						break
					case 'true':
						x = droppableBranchNodeX - 210
						y = droppableBranchNodeY + 270

						break
					case 'false':
						x = droppableBranchNodeX + 210
						y = droppableBranchNodeY + 270

						break
					default:
						break
				}
			}
		}
		return { x, y }
	}

	const connectNodeToDroppableBranch = () => {
		if (droppableBranch) {
			const droppedNode = nodes.find((n) =>
				n.data.parentIds.includes(droppableBranch),
			)
			// Check if the dropped node exists via checking for parentIds containing the current droppableBranch
			if (!droppedNode) return

			const valid = isDropValid(droppedNode.id)
			if (!valid) return

			const conn: Connection = {
				source: droppableBranch.split('&')[0],
				sourceHandle: droppableBranch,
				target: droppedNode.id,
				targetHandle: droppedNode.id,
			}

			dispatch(workflowsActions.updateDroppableBranch(null))
			dispatch(workflowsActions.setCurrentNodeData(droppedNode))

			onConnect(conn)
		}
	}

	const isDropValid = (droppedNodeId: string): boolean => {
		// Check to make sure there's a droppable branch in the first place
		if (!droppableBranch) return false

		// Get all ancestors of the node being connected to (droppableBranch)
		const ancestors = getAllAncestors(droppableBranch, canvasNodes)
		// Check if the dropped node is its own ancestor
		if (ancestors.includes(droppedNodeId)) return false

		// Sanity check: make sure we're not dropping a node onto itself
		if (droppableBranch.includes(droppedNodeId)) return false

		// Don't allow connecting the 0 (trigger) node to anything
		if (droppedNodeId === '0') return false

		return true
	}

	const isBranchConnectable = ({
		nodeId,
		branchId,
	}: {
		nodeId: string
		branchId: string
	}) => {
		const startNode = canvasNodes[branchId.split('&')[0]]
		const upstreamNodes = getUpstreamNodes(startNode)

		if (
			branchId !== nodeId &&
			(canvasNodes[nodeId]?.data.parentIds.some(
				(p) => p.split('&')[0] === branchId.split('&')[0],
			) ||
				upstreamNodes.some((n) => n.id === nodeId))
		) {
			return false
		}

		return true
	}

	const handleSetZoom = useCallback(
		(zoom: number) => {
			zoomTo(zoom)
		},
		[zoomTo],
	)

	const handleConnectIntegrationOnClick = () => {
		if (!integrationListItemToConnect) return

		if (integrationListItemToConnect?.stepType === StepType.WorkflowStart) {
			if (
				!selectedWorkflow?.id ||
				!integrationListItemToConnect?.selectedTrigger
			)
				return

			mutateWorkflow({
				form: {
					id: selectedWorkflow.id,
					type: integrationListItemToConnect.selectedTrigger,
				},
			})

			dispatch(workflowsActions.updateSelectedNode('0'))
		} else {
			if (!droppableBranch) return

			const { nodeType, integrationId, stepType, editableName } =
				integrationListItemToConnect

			const { x, y } = getDroppableBranchPosition()
			const parentIds = [droppableBranch]

			let stepDetails:
				| LogicStepDetails
				| Default_DisplayScheduler
				| MatchRecord
				| null = null
			if (nodeType === RFNodeType.logicNode) {
				stepDetails = createLogicStepDetails(integrationId)
			}

			const id = nanoid(8)
			const newNode: CanvasNode = {
				id,
				type: nodeType,
				position: {
					x,
					y,
				},
				connectable: true,
				draggable: true,
				data: {
					id,
					integrationId,
					editableName,
					description: '',
					stepType,
					children: [],
					parentIds,
					stepDetails,
					nextStep: null,
					x,
					y,
				},
			}

			setNodes((nds) => nds.concat(newNode))
			setTriggerUpdate((prev) => !prev)
			dispatch(workflowsActions.updateSelectedNode(newNode.data.id))
		}

		dispatch(workflowsActions.setIntegrationListItemToConnect(null))
		dispatch(workflowsActions.setClickToConnectInteraction(false))
	}

	const onKeyDown = useCallback((event) => {
		event.preventDefault()

		if (event.key === 'Escape') {
			dispatch(workflowsActions.updateSelectedNode(null))
			dispatch(workflowsActions.updateDroppableBranch(null))
			dispatch(workflowsActions.setClickToConnectInteraction(false))
			dispatch(workflowsActions.setCanvasNodeMenuOpenId(null))
			RFDeselectAll()
			RFReset()
		}
	}, [])

	return (
		<div
			style={{ width: '100%', height: '100%' }}
			ref={reactFlowWrapper}
			id="rf-pane"
		>
			<ReactFlow
				proOptions={PRO_OPTIONS}
				nodes={nodes}
				edges={edges}
				panOnScroll={true}
				nodesDraggable={!isReadOnly}
				zoomOnPinch={true}
				zoomOnScroll={false}
				selectionMode={SelectionMode.Partial}
				connectOnClick
				snapToGrid
				snapGrid={[30, 30]}
				onInit={setReactFlowInstance}
				nodeTypes={nodeTypes}
				edgeTypes={edgeTypes}
				defaultViewport={viewPortOptions}
				minZoom={0.3}
				nodesConnectable={false}
				onNodeDoubleClick={onNodeDoubleClick}
				onNodeClick={onNodeClick}
				onSelectionStart={onSelectionStart}
				onSelectionEnd={onSelectionEnd}
				onSelectionDragStop={onSelectionDragStop}
				onPaneClick={onPaneClick}
				deleteKeyCode=""
				onEdgeMouseEnter={onEdgeMouseEnter}
				onEdgeMouseLeave={onEdgeMouseLeave}
				onEdgeClick={onEdgeClick}
				onConnect={onConnect}
				onNodesChange={onNodesChange}
				onNodeDragStop={onNodeDragStop}
				onNodeDrag={onNodeDrag}
				onEdgesChange={onEdgesChange}
				onDrop={onDrop}
				onKeyDown={onKeyDown}
				onDragOver={onDragOver}
				connectionRadius={80}
				panOnDrag={panOnDrag}
				selectionOnDrag={!panOnDrag}
				onlyRenderVisibleElements
			>
				<Background
					variant={BackgroundVariant.Dots}
					gap={12}
					size={2}
					color="#D9D9D9"
				/>
				<CanvasControlBar
					panOnDrag={panOnDrag}
					setPanOnDrag={setPanOnDrag}
					zoom={viewport.zoom}
					setZoom={handleSetZoom}
				/>
			</ReactFlow>
		</div>
	)
}

export const Canvas = () => {
	return (
		<Flex
			h="100%"
			w="100%"
			justify="center"
			align="center"
			direction="column"
			position="absolute"
			zIndex={1}
			bottom={0}
			left={0}
			userSelect="none"
		>
			<ReactFlowProvider>
				<Flow />
			</ReactFlowProvider>
		</Flex>
	)
}

const isOverlapping = ({
	draggableRect,
	droppableRect,
}: {
	draggableRect: DOMRect | undefined
	droppableRect: DOMRect | undefined
}) => {
	if (draggableRect && droppableRect) {
		return (
			draggableRect.left <= droppableRect.right &&
			draggableRect.right >= droppableRect.left &&
			draggableRect.top <= droppableRect.bottom &&
			draggableRect.bottom >= droppableRect.top
		)
	} else {
		return false
	}
}
