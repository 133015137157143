import { FieldRefNodeValue, LabeledRefNodeValue, RefNodeValue } from './actions'
import { MatchRecord } from './matching'

export interface Hubspot_CreateRecord {
	type: RefNodeValue
	insertHubspotProperties: {
		[hubspotPropertyId: string]: FieldRefNodeValue
	}
}
export interface Hubspot_MergeRecord {
	type: RefNodeValue // Lead, Contact, Account
	insertHubspotProperties: {
		[hubspotPropertyId: string]: FieldRefNodeValue
	}
	duplicateRecordId: LabeledRefNodeValue // the ID of the record being merged
	overwrite: boolean
}

export interface Hubspot_CreateEngagement {
	type: RefNodeValue
	insertHubspotAssociations: {
		objectType: RefNodeValue
		objectId: LabeledRefNodeValue
	} | null
	insertHubspotProperties: {
		[hubspotPropertyId: string]: FieldRefNodeValue
	}
}

export type Hubspot_MatchRecord = MatchRecord

export enum HubSpotEntities {
	Contact = 'Contact',
	Company = 'Company',
	Deal = 'Deal',
	// TODO: Need to add support for required fields for tickets:
	// https://linear.app/withdefault/issue/DEF-3506/add-support-for-required-fields-for-hubspot-ticket-objects
	// Ticket = 'Ticket',
}

export enum HubSpotEngagements {
	Meeting = 'meeting',
	Call = 'call',
	Email = 'email',
	Note = 'note',
	'Postal Mail' = 'postal_mail',
	Task = 'task',
}

export enum HubspotSubscriptionType {
	CONTACT_CREATION = 'contact.creation',
	COMPANY_CREATION = 'company.creation',
}
