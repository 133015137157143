import { useCallback } from 'react'

import HubSpotLogo from '@/assets/logo/hubspot-step.svg'

import { AsyncDrpOptionGenerator } from '../../../components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '../../../components/side-panel/panel-content/DataReferencePicker/components/Option'
import { HubSpotEntities } from '../../../types/hubspot'
import { useHubspotRecordOptionsByType } from '../../useHubspotRecordOptionsByType'

export const useGetHubspotRecordsDrpOptions = (): AsyncDrpOptionGenerator => {
	const { getHubspotRecordOptions } = useHubspotRecordOptionsByType()

	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			return Object.values(HubSpotEntities).map((entity) => {
				return {
					label: entity,
					icon: HubSpotLogo,
					value: getHubspotRecordOptions(entity).map((option) => ({
						label: option.label,
						value: option,
						onSelect: () => {
							const newRfn = {
								refNodeId: option.value,
								variable: option.variable,
								value: null,
								label: option.label,
								icon: HubSpotLogo,
								dataType: entity.toLowerCase(),
							}

							onSelect(newRfn)
						},
					})),
				}
			})
		},
		[getHubspotRecordOptions],
	)
}
