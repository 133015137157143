import { Page, ThemeStyle } from 'shared-utils'

import { Team } from '@/app/user/api/teams/type'
import { FormType } from '@/modules/forms/types'
import { FormWorkflowType } from '@/modules/workflow/types/nodes'
import { FlowStart } from '@/modules/workflow/utils/FlowStart'

export const getCreateFormPayload = (team: Team) => {
	return {
		name: 'Untitled form',
		team_id: team.id,
		type: FormType.regular,
		published: false,
		style: initFormStyle(team),
		workflow: initWorkflow(),
		pages: initPages(),
		workflow_name: 'Untitled workflow',
	}
}

export const getImportFormPayload = (teamId: number) => {
	return {
		name: 'Untitled form',
		team_id: teamId,
		type: FormType.imported,
		published: false,
		workflow: initWorkflow(),
		workflow_name: 'Untitled workflow',
	}
}

export const getCreateTriggerPayload = (team: Team, type: FormType) => {
	return {
		name: 'Untitled form',
		team_id: team.id,
		type: type,
		published: false,
		active: true,
		style: initFormStyle(team),
		workflow: initWorkflow(),
		pages: initPages(),
		workflow_name: 'Untitled workflow',
		meta: {
			trigger_config: null,
		},
	}
}

/* Private */

const initPages = (): Page[] => {
	return [
		{
			id: 123456,
			mediaBlock: null,
			advancedTextBlock: null,
		},
		{
			id: 789012,
			mediaBlock: null,
			advancedTextBlock: null,
		},
	]
}

export const initWorkflow = (): FormWorkflowType => {
	return {
		startNode: '0',
		steps: { '0': FlowStart.data },
	}
}

const initFormStyle = (team?: Team): Partial<ThemeStyle> => {
	return {
		logo: {
			image: team?.logo ?? null,
			side: 'media',
			enabled: true,
		},
		favicon: {
			image: team?.icon || null,
			enabled: true,
		},
	}
}
