export enum ColorTokens {
	white = 'white',
	black = 'black',

	bg_card = 'bg_card',
	bg_card_secondary = 'bg_card_secondary',
	bg_table_header = 'bg_table_header',
	bg_secondary = 'bg_secondary',
	bg_dark = 'bg_dark',
	bg_input = 'bg_input',
	bg = 'bg',
	bg_error = 'bg_error',
	bg_row_selected = 'bg_row_selected',
	bg_hover = 'bg_hover',
	bg_active = 'bg_active',

	text_primary = 'text_primary',
	text_secondary = 'text_secondary',
	text_tertiary = 'text_tertiary',
	text_table_header = 'text_table_header',
	text = 'text',
	text_inverted = 'text_inverted',
	text_error = 'text_error',
	text_placeholder = 'text_placeholder',
	text_input_description = 'text_input_description',

	border_primary = 'border_primary',
	border_secondary_btn = 'border_secondary_btn',
	border_secondary = 'border_secondary',

	accent = 'accent',
	hover_accent = 'hover_accent',
	hover = 'hover',
	hover_secondary = 'hover_secondary',
	icon_primary = 'icon_primary',
	danger = 'danger',
	danger_background = 'danger_background',
	success = 'success',
	success_background = 'success_background',
	warning = 'warning',
	warning_background = 'warning_background',
	card_secondary = 'card_secondary',
	error_background_primary = 'error_background_primary',
	log_error_text = 'log_error_text',
	log_error_border = 'log_error_border',
	log_error_bg = 'log_error_bg',
	crown_primary = 'crown_primary',
}

type SematicColorsType = Record<
	ColorTokens,
	{
		default: string
		_dark: string
	}
>

export const generateSemanticColors = (): SematicColorsType => {
	const keys = Object.keys(ColorTokens) as ColorTokens[]

	const colors = {} as SematicColorsType

	keys.forEach((token) => {
		colors[token] = {
			default: LIGHT_COLORS[token],
			_dark: DARK_COLORS[token],
		}
	})

	return colors
}

export const COLORS = {
	default: {
		accent: '#8E8EF8',
		accentHover: '#6062bf',
		second: '#8E8E8E',
		faded: 'rgba(88,39,196,0.14)',
	},
	gray: {
		1: '#FAFBFF',
		2: '#F5F8FF',
		3: '#E8EDFA',
		4: '#DFE4F0',
		5: '#CED5E5',
		6: '#C3C9D9',
		7: '#B4BBCC',
		8: '#98A0B3',
		9: '#828999',
		10: '#697080',
		11: '#414861',
		12: '#222B48',
	},
	background: {
		1: '#181818',
		2: '#1A1A1A',
		3: '#333333',
		4: '#4C4C4C',
		5: '#666666',
		6: '#7F7F7F',
		7: '#98999A',
		8: '#B3B3B3',
		9: '#CCCCCC',
		10: '#E6E6E6',
		11: '#F0F0F0',
		12: '#FAFAFA',
	},
	purple: {
		1: '#FAFAFF',
		2: '#F5F5FF',
		3: '#EDEDFF',
		4: '#E5E5FF',
		5: '#D9D9FF',
		6: '#C8C8FA',
		7: '#ACACF2',
		8: '#9494F2',
		9: '#6363F2',
		10: '#5555F2',
		11: '#4C4CD9',
		12: '#242466',
	},
	red: {
		1: '#FFFAFA',
		2: '#FAF1F0',
		3: '#F2E0DF',
		4: '#F2D1CE',
		5: '#E5B2AE',
		6: '#E09994',
		7: '#D97F79',
		8: '#D4544C',
		9: '#D42C22',
		10: '#BF1D13',
		11: '#A61108',
		12: '#590904',
	},
	green: {
		1: '#FAFFFC',
		2: '#F2FCF7',
		3: '#DFF7EB',
		4: '#CEF2E0',
		5: '#B0EBCD',
		6: '#92E0B9',
		7: '#7ACFA4',
		8: '#22B56C',
		9: '#0B8146',
		10: '#096637',
		11: '#07522C',
		12: '#04331C',
	},
	blackAlpha: {
		1: 'rgba(0, 0, 0, 0.014)',
		2: 'rgba(0, 0, 0, 0.03)',
		3: 'rgba(0, 0, 0, 0.05)',
		4: 'rgba(0, 0, 0, 0.06)',
		5: 'rgba(0, 0, 0, 0.08)',
		6: 'rgba(0, 0, 0, 0.12)',
		7: 'rgba(0, 0, 0, 0.16)',
		8: 'rgba(0, 0, 0, 0.24)',
		9: 'rgba(0, 0, 0, 0.4)',
		10: 'rgba(0, 0, 0, 0.48)',
		11: 'rgba(0, 0, 0, 0.56)',
		12: '#000000',
	},
	whiteAlpha: {
		1: 'rgba(255, 255, 255, 0.014)',
		2: 'rgba(255, 255, 255, 0.03)',
		3: 'rgba(255, 255, 255, 0.05)',
		4: 'rgba(255, 255, 255, 0.06)',
		5: 'rgba(255, 255, 255, 0.08)',
		6: 'rgba(255, 255, 255, 0.12)',
		7: 'rgba(255, 255, 255, 0.16)',
		8: 'rgba(255, 255, 255, 0.24)',
		9: 'rgba(255, 255, 255, 0.4)',
		10: 'rgba(255, 255, 255, 0.48)',
		11: 'rgba(255, 255, 255, 0.56)',
		12: '#FFFFFF',
	},
	orange: {
		1: '#FFFBFA',
		2: '#FAF3F0',
		3: '#FAE8E1',
		4: '#F5DBD0',
		5: '#F0C8B6',
		6: '#F0BAA3',
		7: '#EB9671',
		8: '#EB6E38',
		9: '#E05418',
		10: '#C74B15',
		11: '#943710',
		12: '#612106',
	},
	teal: {
		100: '#7DEDF3',
	},
	sky: {
		200: '#DBF6FF',
		900: '#00A4DB',
	},
}

type BaseColor = Record<ColorTokens, string>

const LIGHT_COLORS: BaseColor = {
	[ColorTokens.white]: COLORS.whiteAlpha[12],
	[ColorTokens.black]: COLORS.blackAlpha[12],

	// backgrounds
	[ColorTokens.bg_card]: COLORS.whiteAlpha[12],
	[ColorTokens.bg_card_secondary]: '#FCFCFC',
	[ColorTokens.bg_table_header]: COLORS.background[12],
	[ColorTokens.bg_secondary]: COLORS.background[12],
	[ColorTokens.bg_dark]: COLORS.gray[12],
	[ColorTokens.bg_input]: COLORS.background[12],
	[ColorTokens.bg]: COLORS.background[12],
	[ColorTokens.bg_error]: COLORS.red[2],
	[ColorTokens.bg_row_selected]: COLORS.purple[2],
	[ColorTokens.bg_hover]: COLORS.purple[2],
	[ColorTokens.bg_active]: COLORS.purple[3],

	// texts
	[ColorTokens.text_primary]: COLORS.background[2],
	[ColorTokens.text_secondary]: COLORS.gray[9],
	[ColorTokens.text_tertiary]: COLORS.gray[9], // Missing
	[ColorTokens.text_table_header]: COLORS.gray[9], //Missing

	[ColorTokens.text]: COLORS.gray[12],
	[ColorTokens.text_inverted]: COLORS.whiteAlpha[12],
	[ColorTokens.text_error]: COLORS.red[9],
	[ColorTokens.text_placeholder]: COLORS.gray[9],
	[ColorTokens.text_input_description]: COLORS.gray[10],

	// border
	[ColorTokens.border_primary]: COLORS.gray[4],
	[ColorTokens.border_secondary_btn]: COLORS.background[10],
	[ColorTokens.border_secondary]: '#F1F1F1',

	// hover
	[ColorTokens.accent]: COLORS.purple[9],
	[ColorTokens.hover_accent]: COLORS.purple[11],
	[ColorTokens.hover]: COLORS.background[9],
	[ColorTokens.hover_secondary]: COLORS.background[9],

	// icons
	[ColorTokens.icon_primary]: COLORS.gray[12],

	[ColorTokens.danger]: COLORS.red[9],
	[ColorTokens.danger_background]: COLORS.red[2],
	[ColorTokens.success]: COLORS.green[9],
	[ColorTokens.success_background]: COLORS.green[2],
	[ColorTokens.warning]: COLORS.orange[9],
	[ColorTokens.warning_background]: COLORS.orange[2],

	[ColorTokens.card_secondary]: '#F1F1F1',
	[ColorTokens.error_background_primary]: '#F2CED4',

	[ColorTokens.log_error_text]: '#E06118',
	[ColorTokens.log_error_border]: '#EB9E71',
	[ColorTokens.log_error_bg]: '#FAF4F0',

	[ColorTokens.crown_primary]: '#FFAB0F',
}

const DARK_COLORS: BaseColor = {
	[ColorTokens.white]: COLORS.whiteAlpha[12],
	[ColorTokens.black]: COLORS.blackAlpha[12],

	// backgrounds
	[ColorTokens.bg_card]: COLORS.whiteAlpha[12],
	[ColorTokens.bg_card_secondary]: '#FCFCFC',
	[ColorTokens.bg_table_header]: COLORS.background[12],
	[ColorTokens.bg_secondary]: COLORS.background[12],
	[ColorTokens.bg_dark]: COLORS.gray[12],
	[ColorTokens.bg_input]: COLORS.background[12],
	[ColorTokens.bg]: COLORS.background[12],
	[ColorTokens.bg_error]: COLORS.red[2],
	[ColorTokens.bg_row_selected]: COLORS.purple[2],
	[ColorTokens.bg_hover]: COLORS.purple[2],
	[ColorTokens.bg_active]: COLORS.purple[3],

	// texts
	[ColorTokens.text_primary]: COLORS.gray[12],
	[ColorTokens.text_secondary]: COLORS.gray[9],
	[ColorTokens.text_tertiary]: COLORS.gray[9], // Missing
	[ColorTokens.text_table_header]: COLORS.gray[9], //Missing

	[ColorTokens.text]: COLORS.gray[12],
	[ColorTokens.text_inverted]: COLORS.whiteAlpha[12],
	[ColorTokens.text_error]: COLORS.red[9],
	[ColorTokens.text_placeholder]: COLORS.gray[9],
	[ColorTokens.text_input_description]: COLORS.gray[10],

	// border
	[ColorTokens.border_primary]: COLORS.blackAlpha[4],
	[ColorTokens.border_secondary_btn]: COLORS.background[10],
	[ColorTokens.border_secondary]: '#F1F1F1',

	// hover
	[ColorTokens.accent]: COLORS.purple[9],
	[ColorTokens.hover_accent]: COLORS.purple[11],
	[ColorTokens.hover]: COLORS.background[9],
	[ColorTokens.hover_secondary]: COLORS.background[9],

	// icons
	[ColorTokens.icon_primary]: COLORS.gray[12],

	[ColorTokens.danger]: COLORS.red[9],
	[ColorTokens.danger_background]: COLORS.red[2],
	[ColorTokens.success]: COLORS.green[9],
	[ColorTokens.success_background]: COLORS.green[2],
	[ColorTokens.warning]: COLORS.orange[9],
	[ColorTokens.warning_background]: COLORS.orange[2],

	[ColorTokens.card_secondary]: '#F1F1F1',
	[ColorTokens.error_background_primary]: '#F2CED4',

	[ColorTokens.log_error_text]: '#E06118',
	[ColorTokens.log_error_border]: '#EB9E71',
	[ColorTokens.log_error_bg]: '#FAF4F0',

	[ColorTokens.crown_primary]: '#FFAB0F',
}

export const SemanticColors = generateSemanticColors()
