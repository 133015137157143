import { Icons } from '@ds/Icons'
import { matchPath } from 'react-router-dom'

import { useAppSelector } from '@/app/hooks'
import { selectSelectedViewId } from '@/app/leads/slice/selectors'

export type SidebarSuboptionType = {
	title: string
	navTo: string
	isActive?: boolean
}

export type SidebarOptionType = {
	title: string
	icon: Icons
	navTo: string
	suboptions?: SidebarSuboptionType[]
	hide?: boolean
	isActive?: boolean
}

export const useSidebarOptions = () => {
	const selectedViewId = useAppSelector(selectSelectedViewId)

	const getActive = (matchTo: string) => {
		return !!matchPath(
			{
				path: matchTo,
				caseSensitive: false,
				end: false,
			},
			location.pathname,
		)
	}

	const OPTIONS: SidebarOptionType[] = [
		{
			title: 'Pipeline',
			icon: Icons.table,
			navTo: `/leads/views/${selectedViewId}`,
			isActive:
				getActive(`/leads/views/${selectedViewId}`) || getActive('/companies'),
		},
		{
			title: 'Meetings',
			icon: Icons.meetings,
			navTo: '/meetings',
			isActive: getActive('/meetings'),
			suboptions: [
				{
					title: 'My meetings',
					navTo: 'meetings/personal',
					isActive: getActive('/meetings/personal'),
				},
				{
					title: 'Team meetings',
					navTo: 'meetings/team',
					isActive: getActive('/meetings/team'),
				},
			],
		},
		{
			title: 'Forms',
			icon: Icons.input,
			navTo: '/forms/default',
			isActive: getActive('/forms'),
			suboptions: [
				{
					title: 'Default forms',
					navTo: '/forms/default',
					isActive: getActive('/forms/default'),
				},
				{
					title: 'Webforms',
					navTo: 'forms/webforms',
					isActive: getActive('forms/webforms'),
				},
			],
		},
		{
			title: 'Scheduler',
			icon: Icons.calendar_empty,
			isActive: getActive('/scheduler'),
			navTo: '/scheduler/events',
			suboptions: [
				{
					title: 'Event types',
					navTo: `scheduler/events/list/personal`,
					isActive:
						getActive('scheduler/events/list/personal') ||
						getActive('scheduler/event/:eventId'),
				},
				{
					navTo: 'scheduler/queues',
					title: 'Queues',
					isActive:
						getActive('scheduler/queues') ||
						getActive('scheduler/queue/:queueId'),
				},
				{
					title: 'Scheduler style',
					navTo: 'scheduler/style',
					isActive: getActive('scheduler/style'),
				},
			],
		},
		{
			title: 'Workflows',
			icon: Icons.workflow,
			navTo: '/workflows',
			isActive: getActive('/workflows'),
		},
	]

	return OPTIONS.filter(({ hide }) => !hide)
}
