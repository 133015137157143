import {
	Flex,
	Input,
	InputProps,
	Switch,
	Text,
	Tooltip,
} from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { functionsIn, isEqual, omit } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { CSSObjectWithLabel } from 'react-select'

import { useAppSelector } from '@/app/hooks'
import { Select } from '@/common/components/Select'
import { useIsWorkflowAdvancedMode } from '@/common/hooks/feature-flags/useIsWorkflowAdvancedMode'
import { selectCurrentCanvasNodes } from '@/modules/workflow/slice/selectors'
import { LabeledRefNodeValue } from '@/modules/workflow/types/actions'
import {
	filterEmptyOptions,
	optionHasSubMenu,
} from '@/modules/workflow/utils/drpHelpers'
import { getReferencedNodeLabel } from '@/modules/workflow/utils/getReferencedNodeLabel'
import { TOOLTIP_ENUM } from '@/styles/components/tooltip'
import { SHADOWS } from '@/styles/constants'
import { WEIGHT } from '@/styles/typography'

import { PanelInput } from '../../panel-variants/PanelInput'
import { Menu } from './components/Menu'
import { DataReferencePickerOption, Option } from './components/Option'
import { SingleValue } from './components/SingleValue'
export type AsyncDrpOptionGenerator = ({
	onSelect,
}: {
	onSelect: (value: any) => void
}) => Promise<DataReferencePickerOption[]>

type BaseProps = {
	getInitialOptions: AsyncDrpOptionGenerator
	controlStyles?: CSSObjectWithLabel
	customValueInputStyles?: InputProps
	flexDirection?: 'column' | 'row'
	triggerOptionsUpdate?: boolean
	searchPlaceholder?: string
	onSelect: (op: any) => void
}

type ClearValueProps =
	| {
			clearValue: () => void
			isClearable: true
	  }
	| {
			clearValue?: never
			isClearable?: false
	  }

type IsMultiProps =
	| {
			isMulti: true
			enableCustomValue?: false
			initialOption: { label: string; value: any }[] | null
			removeValue: (value: any) => void
	  }
	| {
			isMulti?: false
			enableCustomValue?: boolean
			initialOption: { label: string; value: any } | null
			removeValue?: never
	  }

type DataReferencePickerProps = BaseProps & ClearValueProps & IsMultiProps

export const DEFAULT_SEARCH_PLACEHOLDER = 'Search attributes...'
export const CUSTOM_VALUE_LABEL = 'Custom value'

export const DataReferencePicker = React.memo(
	({
		getInitialOptions,
		initialOption,
		enableCustomValue,
		controlStyles,
		customValueInputStyles,
		flexDirection = 'column',
		searchPlaceholder,
		isClearable,
		isMulti,
		triggerOptionsUpdate,
		clearValue,
		onSelect,
		removeValue,
	}: DataReferencePickerProps) => {
		const isWorkflowAdvancedModeEnabled = useIsWorkflowAdvancedMode()

		// React-select has a menuIsOpen prop but it stays undefined upon initial render.
		// This gives us a cleaner and more intuitive way to control the state of the menu.
		const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false)
		const [isCustomValue, setIsCustomValue] = useState<boolean>(
			!isMulti && initialOption?.label === CUSTOM_VALUE_LABEL,
		)

		const [options, setOptions] = useState<any>([])

		const handleSetCustomValue = (customValue: string) => {
			const newRfn: LabeledRefNodeValue = {
				refNodeId: null,
				variable: null,
				value: customValue,
				label: CUSTOM_VALUE_LABEL,
			}
			onSelect(newRfn)
		}

		useEffect(() => {
			getInitialOptions({ onSelect }).then((optionsData) => {
				const filteredOptions = filterEmptyOptions(optionsData)

				if (enableCustomValue) {
					const customDrpOption: DataReferencePickerOption = {
						label: CUSTOM_VALUE_LABEL,
						value: 'custom',
						onSelect: () => {
							handleSetCustomValue('')
							setIsCustomValue(true)
						},
					}

					filteredOptions.push(customDrpOption)
				}

				setOptions(filteredOptions)
			})
		}, [triggerOptionsUpdate])

		const [optionsStack, setOptionsStack] = useState<
			DataReferencePickerOption[][]
		>([options])
		const [optionHistoryStack, setOptionHistoryStack] = useState<
			DataReferencePickerOption[]
		>([])

		useEffect(() => {
			setOptionsStack([options])
		}, [options])

		const handleOptionChange = (op, actionMeta) => {
			if (!op) return

			const currentOption = isMulti ? actionMeta.option : op
			const { value } = currentOption || {}

			const actionType = actionMeta.action

			if (actionType === 'select-option') {
				if (optionHasSubMenu(value)) {
					setOptionHistoryStack([...optionHistoryStack, currentOption])
					setOptionsStack([...optionsStack, value])
				} else {
					if (currentOption.onSelect) {
						if (
							isMulti &&
							initialOption?.some((o) => isEqual(o.value, value))
						) {
							removeValue(value)
						} else {
							if (isCustomValue && value !== 'custom') {
								setIsCustomValue(false)
							}

							currentOption.onSelect(value)
						}
					}

					if (!isMulti) {
						setMenuIsOpen(false)
					}
				}
			} else if (
				(actionType === 'remove-value' || actionType === 'deselect-option') &&
				isMulti
			) {
				const removedValue =
					actionType === 'remove-value' ? actionMeta.removedValue.value : value

				removeValue(removedValue)
			}
		}

		const [tooltipText, setTooltipText] = useState<string>('')
		const singleValueRef = useRef<HTMLDivElement>(null)

		const [isTextOverflowing, setIsTextOverflowing] = useState<boolean>(false)
		useEffect(() => {
			if (singleValueRef.current) {
				setIsTextOverflowing(
					singleValueRef.current.scrollWidth >
						singleValueRef.current.clientWidth,
				)
			}
		}, [tooltipText])

		const enableTooltip = isTextOverflowing && !menuIsOpen

		const [isAdvancedMode, setIsAdvancedMode] = useState<boolean>(false)
		const [advancedVariable, setAdvancedVariable] = useState<string>(
			(!isMulti && initialOption?.value?.variable) || '',
		)

		useEffect(() => {
			if (!isMulti && initialOption?.value?.variable) {
				setAdvancedVariable(initialOption.value.variable)
			}
		}, [initialOption])

		const handleAdvancedModeToggle = () => {
			setIsAdvancedMode(!isAdvancedMode)
		}

		const handleAdvancedVariableChange = (
			e: React.ChangeEvent<HTMLInputElement>,
		) => {
			setAdvancedVariable(e.target.value)
		}

		const handleAdvancedVariableBlur = () => {
			if (!isMulti) {
				const newRfn = {
					...initialOption?.value,
					variable: advancedVariable,
					label: `${advancedVariable} (from Advanced Mode)`,
				}
				onSelect(newRfn)
			}
		}

		const handleAdvancedVariableKeyDown = (
			e: React.KeyboardEvent<HTMLInputElement>,
		) => {
			if (e.key === 'Enter') {
				handleAdvancedVariableBlur()
			}
		}

		return (
			<Tooltip
				label={tooltipText}
				isDisabled={!enableTooltip || isCustomValue || isAdvancedMode}
				variant={TOOLTIP_ENUM.workflows_side_panel}
			>
				<Flex
					direction={flexDirection}
					gap={flexDirection === 'column' ? 1.5 : 2}
				>
					{isAdvancedMode && (
						<Input
							h="34px"
							defaultValue={advancedVariable}
							placeholder="Enter variable ($.field.field...)"
							onChange={handleAdvancedVariableChange}
							onBlur={handleAdvancedVariableBlur}
							onKeyDown={handleAdvancedVariableKeyDown}
							{...customValueInputStyles}
						/>
					)}

					{!isAdvancedMode && (
						<Select
							value={
								Array.isArray(initialOption) || initialOption?.label
									? initialOption
									: null
							}
							options={optionsStack[optionsStack.length - 1]}
							onChange={handleOptionChange}
							menuIsOpen={menuIsOpen}
							onMenuOpen={() => setMenuIsOpen(true)}
							onMenuClose={() => setMenuIsOpen(false)}
							closeMenuOnSelect={false}
							hideSelectedOptions={false}
							placeholder={
								menuIsOpen
									? searchPlaceholder || DEFAULT_SEARCH_PLACEHOLDER
									: 'Select a value'
							}
							isSearchable
							isMulti={isMulti}
							isLoading={options.length === 0}
							menuPlacement="auto"
							styles={{
								container: (baseStyles) => ({
									...baseStyles,
									width: '100%',
								}),
								control: (baseStyles, state) => ({
									...baseStyles,
									minHeight: '34px',
									borderColor: '#E6E6E6',
									background: COLORS.whiteAlpha[12],
									borderRadius: '8px',
									cursor: 'pointer',
									boxShadow: state.isFocused ? SHADOWS.focused : 'none',
									'&:hover': {
										borderColor: state.isFocused
											? 'transparent'
											: COLORS.background[9],
									},
									...controlStyles,
								}),
								menu: (baseStyles) => ({
									...baseStyles,
									backgroundColor: COLORS.whiteAlpha[12],
									minWidth: '100%',
									width: 'max-content',
									maxWidth: '320px',
									borderColor: 'transparent',
									zIndex: 999,
									borderRadius: '8px',
									boxShadow: SHADOWS.popover,
								}),
								menuList: (baseStyles) => {
									return {
										...baseStyles,
										maxHeight: '200px',
										padding: '4px',
									}
								},
								singleValue: (baseStyles) => ({
									...baseStyles,
									color: COLORS.gray[12],
									fontSize: '14px',
									margin: '0',
								}),
								placeholder: (baseStyles) => ({
									...baseStyles,
									color: COLORS.background[6],
									fontSize: '14px',
									fontWeight: WEIGHT.medium,
									margin: '0',
								}),
								input: (baseStyles) => ({
									...baseStyles,
									color: COLORS.gray[12],
									fontSize: '14px',
									fontWeight: WEIGHT.medium,
									margin: '0',
								}),
							}}
							components={{
								Option,
								SingleValue: (props) => {
									const canvasNodes = useAppSelector(selectCurrentCanvasNodes)
									const referencedNode =
										canvasNodes[props.data.value?.refNodeId]

									const { label, value } = props.data
									const icon = value?.icon || null

									const fullLabel =
										referencedNode && referencedNode.id !== '0'
											? getReferencedNodeLabel({
													label,
													editableName: referencedNode.data.editableName,
												})
											: label

									useEffect(() => {
										setTooltipText(fullLabel)
									}, [fullLabel])

									return (
										<SingleValue
											icon={icon}
											label={fullLabel}
											ref={singleValueRef}
											{...props}
										/>
									)
								},
								Menu: (props) => {
									const handleBackClick = () => {
										setOptionHistoryStack(optionHistoryStack.slice(0, -1))
										setOptionsStack(optionsStack.slice(0, -1))
									}

									const selectedCategory =
										optionsStack.length > 1
											? optionHistoryStack[optionHistoryStack.length - 1].label
											: null

									return (
										<Menu
											handleBackClick={handleBackClick}
											selectedCategory={selectedCategory}
											{...props}
										/>
									)
								},
							}}
							isClearable={isClearable}
							clearValue={clearValue}
						/>
					)}

					{!isMulti &&
						isWorkflowAdvancedModeEnabled &&
						initialOption?.value?.variable &&
						!isCustomValue && (
							<Flex alignItems="center" justifyContent="flex-start">
								<Switch
									size="sm"
									isChecked={isAdvancedMode}
									onChange={handleAdvancedModeToggle}
								/>
								<Text ml={2} fontSize="sm">
									Advanced Mode
								</Text>
							</Flex>
						)}

					{enableCustomValue && isCustomValue && (
						<PanelInput
							initialValue={initialOption?.value?.value || ''}
							placeholder="Enter a custom value"
							onBlur={(e) => handleSetCustomValue(e.target.value)}
							{...customValueInputStyles}
						/>
					)}
				</Flex>
			</Tooltip>
		)
	},
	(prevProps, nextProps) => {
		return isEqual(
			omit(prevProps, functionsIn(prevProps)),
			omit(nextProps, functionsIn(nextProps)),
		)
	},
)
