import { Page, PartialExcept, ThemeStyle } from 'shared-utils'

import { CreatedBy, UpdatedBy } from '@/app/user/api/members/type'
import { SubmissionTable } from '@/modules/submission/types'
import { ImportedFormImportData } from '@/modules/webforms/type'
import { FormWorkflowType } from '@/modules/workflow/types/nodes'

import { QuestionTable } from './questions'

export interface HiddenField {
	questionToHideId: string
	answerId: string
	comparator: string
	value: string
}

export interface LogicJump {
	destinationId: string
	answerId: string
	comparator: string
	value: string
}

export interface FormFieldMapping {
	type: string
	to: string
	name: string
	question_id: string
}

export enum FormType {
	regular = 'regular',
	imported = 'imported',
	playbook = 'playbook',
	draft_trigger = 'draft_trigger',
	salesforce_object_create_trigger = 'salesforce_object_create_trigger',
	salesforce_object_update_trigger = 'salesforce_object_update_trigger',
	hubspot_trigger = 'hubspot_trigger',
	meeting_booked_trigger = 'meeting_booked_trigger',
	meeting_cancelled_trigger = 'meeting_cancelled_trigger',
	meeting_rescheduled_trigger = 'meeting_rescheduled_trigger',
	meeting_reassigned_trigger = 'meeting_reassigned_trigger',
	meeting_no_show_trigger = 'meeting_no_show_trigger',
	tag_assigned_trigger = 'tag_assigned_trigger',
	webhook_trigger = 'webhook_trigger',
}

export interface FormTable {
	id: number
	name: string | null
	description: string | null
	type: FormType
	published: boolean
	team_id: number
	form_version: number
	theme_id: number
	created_at: string
	updated_at: string
	style: ThemeStyle | null
	workflow: FormWorkflowType | null
	fields_map: FormFieldMapping[]
	hidden_fields: HiddenField[]
	revision_number: number
	logic_jumps: LogicJump[]
	meta: {
		import_data?: ImportedFormImportData | null
		trigger_config?: any | null
	}
	deleted_at: string | null
	published_at: string | null
	pages: Page[]
	updated_by: UpdatedBy | null
	created_by: CreatedBy | null
	active: boolean
	workflow_name?: string | null
	workflow_description?: string | null
}

export interface FormVersionTable extends FormTable {
	form_id: number
}

export interface CreateFormTablePayload {
	form: Partial<FormTable> & { team_id: number }
}

export interface UpdateFormTablePayload {
	form: PartialExcept<FormTable, 'id'>
}

export interface UpsertMeetingFormPayload {
	form: Partial<FormTable>
}

export interface DuplicateFormPayload {
	form: Partial<FormTable>
}
// ---------------------- 3.0 ----------------------

export interface PageWithQuestions extends Page {
	questions: QuestionTable[]
}

export enum BlockType {
	mediaBlock = 'mediaBlock',
	page = 'page',
	logo = 'logo',
	question = 'question',
	adv_text_block = 'adv_text_block',
	media_area = 'media_area',
	form_area = 'form_area',
	button = 'button',
	form_group = 'form_group',
}

// ---------------------- Responses ----------------------

export interface SubmissionWithLead extends SubmissionTable {
	lead: {
		first_name: string | null
		last_name: string | null
	}
}

export enum SubmissionAttributes {
	'created_at' = 'created_at',
	'email' = 'email',
	'first_name' = 'first_name',
	'last_name' = 'last_name',
}

export interface FormPublishPayload {
	formId: string | null
}

export type FormRevertToVersionPayload = FormPublishPayload

export interface FormImportPayload {
	questions: Partial<QuestionTable>[]
	meta: any
	formId: string
	name: string
}

export type CreateSubmissionDto = {
	form_id: number

	team_id: number

	email: string

	responses: ResponseEntity[]

	completed: boolean

	form_version: number

	utm_capture_id?: string
}

export type ResponseEntity = {
	id: string

	question_id: number

	value: string[]

	form_version: number

	submission?: number

	created_at?: Date

	deleted_at?: Date | null

	updated_at?: Date | null
}
