import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TestStepType } from '../hooks/useTestSteps'
import { ConditionBranch } from '../types/logic'
import { NavigationId } from '../types/navigation'
import { CanvasNode, CanvasNodes, DnDNodeData } from '../types/nodes'

export interface WorkflowsState {
	isSidePanelContentOpen: boolean
	selectedSidePanelNavigationId: NavigationId | null
	selectedWorkflowId: number | null
	selectedCanvasNodeId: string | null
	droppableBranchId: string | null
	syncWorkflow: boolean // This is used to update local nodes with the updated nodes
	salesforceFields: Record<string, any[]> | null
	hubspotProperties: Record<string, any[]> | null
	canvasNodeMenuOpenId: string | null
	conditionalObject: { branches: ConditionBranch[] }
	mappingsObject: { [id: string]: any }
	currentCanvasNodes: CanvasNodes
	currentNodeData: CanvasNode | null
	clickToConnectInteraction: boolean
	integrationListItemToConnect: DnDNodeData | null
	testWorkflowFormData: Record<string, string | string[]>
	testWorkflowSettings: {
		meetingBooked: { value: string; label: string }
	} | null
	testWorkflowSteps: TestStepType[]
}

export const initialState: WorkflowsState = {
	isSidePanelContentOpen: true,
	selectedWorkflowId: null,
	selectedCanvasNodeId: null,
	droppableBranchId: null,
	syncWorkflow: false,
	selectedSidePanelNavigationId: NavigationId.AddObjects,
	salesforceFields: null,
	hubspotProperties: null,
	canvasNodeMenuOpenId: null,
	conditionalObject: { branches: [] },
	mappingsObject: {},
	currentCanvasNodes: {},
	currentNodeData: null,
	clickToConnectInteraction: false,
	integrationListItemToConnect: null,
	testWorkflowFormData: {},
	testWorkflowSettings: {
		meetingBooked: { value: 'true', label: 'Meeting booked' },
	},
	testWorkflowSteps: [],
}

export const workflowsSlice = createSlice({
	name: 'workflows',
	initialState,
	reducers: {
		setIsSidePanelContentOpen: (state, action: PayloadAction<boolean>) => {
			state.isSidePanelContentOpen = action.payload
		},
		setSelectedSidePanelNavigationId: (
			state,
			action: PayloadAction<NavigationId | null>,
		) => {
			state.selectedSidePanelNavigationId = action.payload
		},
		setSelectedWorkflowId: (state, action: PayloadAction<number | null>) => {
			state.selectedWorkflowId = action.payload
		},
		updateSelectedNode: (state, action: PayloadAction<string | null>) => {
			state.selectedCanvasNodeId = action.payload
		},
		updateDroppableBranch: (state, action: PayloadAction<string | null>) => {
			state.droppableBranchId = action.payload
		},
		setSyncWorkflow: (state, action: PayloadAction<boolean>) => {
			state.syncWorkflow = action.payload
		},
		setSalesforceFields: (
			state,
			action: PayloadAction<Record<string, string[]> | null>,
		) => {
			state.salesforceFields = action.payload
		},
		setHubspotProperties: (
			state,
			action: PayloadAction<Record<string, string[]> | null>,
		) => {
			state.hubspotProperties = action.payload
		},
		setCanvasNodeMenuOpenId: (state, action: PayloadAction<string | null>) => {
			state.canvasNodeMenuOpenId = action.payload
		},
		setConditionalObject: (
			state,
			action: PayloadAction<{ branches: ConditionBranch[] }>,
		) => {
			state.conditionalObject = action.payload
		},
		setCurrentCanvasNodes: (state, action: PayloadAction<CanvasNodes>) => {
			state.currentCanvasNodes = action.payload
		},
		setCurrentNodeData: (state, action: PayloadAction<CanvasNode | null>) => {
			state.currentNodeData = action.payload
		},
		setClickToConnectInteraction: (state, action: PayloadAction<boolean>) => {
			state.clickToConnectInteraction = action.payload
		},
		setIntegrationListItemToConnect: (
			state,
			action: PayloadAction<DnDNodeData | null>,
		) => {
			state.integrationListItemToConnect = action.payload
		},
		setTestWorkflowFormData: (
			state,
			action: PayloadAction<Record<string, string | string[]>>,
		) => {
			state.testWorkflowFormData = action.payload
		},
		updateTestWorkflowFormData: (
			state,
			action: PayloadAction<{ questionId: string; value: string | string[] }>,
		) => {
			state.testWorkflowFormData[action.payload.questionId] =
				action.payload.value
		},
		setTestWorkflowSettings: (
			state,
			action: PayloadAction<{
				meetingBooked: { value: string; label: string }
			}>,
		) => {
			state.testWorkflowSettings = action.payload
		},
		setTestWorkflowSteps: (state, action: PayloadAction<TestStepType[]>) => {
			state.testWorkflowSteps = action.payload
		},
	},
})

export const workflowsActions = workflowsSlice.actions
export const workflowsReducer = workflowsSlice.reducer
