import {
	LayoutProps,
	NumberInput as ChakraNumberInput,
	NumberInputField,
} from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'

import { Borders } from '@/styles/borders'

interface Props extends LayoutProps {
	defaultValue?: number
	value?: number
	onChange?: (value: string) => void
	max?: number
}

export const NumberInput = ({
	defaultValue,
	value,
	onChange,
	max,
	...props
}: Props) => {
	return (
		<ChakraNumberInput
			min={1}
			max={max}
			defaultValue={defaultValue}
			value={value}
			onChange={onChange}
			sx={{
				'--number-input-stepper-width': '0px',
				'--number-input-input-padding': '8px',
			}}
		>
			<NumberInputField
				w="45px"
				h="28px"
				outline="none"
				px={2}
				border={Borders.primary}
				_focus={{
					borderColor: COLORS.purple[7],
					boxShadow: 'none',
					borderWidth: '2px',
				}}
				_hover={{
					borderColor: COLORS.background[9],
				}}
				fontSize="12px"
				fontWeight={500}
				{...props}
			/>
		</ChakraNumberInput>
	)
}
