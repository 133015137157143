import { Flex, Tooltip } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { CSSProperties, useEffect, useRef, useState } from 'react'
import { GroupBase, Props, StylesConfig } from 'react-select'

import { Select } from '@/common/components/Select'
import { SelectVariant } from '@/common/components/Select/type'
import { TOOLTIP_ENUM } from '@/styles/components/tooltip'
import { SHADOWS } from '@/styles/constants'
import { WEIGHT } from '@/styles/typography'

import { SingleValue } from './components/SingleValue'

type BaseProps = {
	styles?: StylesConfig
	placeholder?: string
	controlStyles?: CSSProperties
	width?: string
	minHeight?: string
	matchWidth?: boolean
	variant?: SelectVariant
}

type CustomProps = BaseProps &
	(
		| {
				clearValue: () => void
				isClearable: true
		  }
		| {
				clearValue?: never
				isClearable?: false
		  }
	)

export const PanelSelect = <
	Option,
	IsMulti extends boolean = false,
	Group extends GroupBase<Option> = GroupBase<Option>,
>({
	controlStyles,
	placeholder,
	isClearable,
	clearValue,
	...props
}: Props<Option, IsMulti, Group> & CustomProps) => {
	const [tooltipText, setTooltipText] = useState<string>('')
	const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false)
	const singleValueRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (singleValueRef.current) {
			setTooltipText(singleValueRef.current.textContent || '')
		}
	}, [props.value])

	const [isTextOverflowing, setIsTextOverflowing] = useState<boolean>(false)
	useEffect(() => {
		if (singleValueRef.current) {
			setIsTextOverflowing(
				singleValueRef.current.scrollWidth > singleValueRef.current.clientWidth,
			)
		}
	}, [tooltipText])

	const enableTooltip = isTextOverflowing && !menuIsOpen

	return (
		<Tooltip
			label={tooltipText}
			isDisabled={!enableTooltip}
			variant={TOOLTIP_ENUM.workflows_side_panel}
		>
			<Flex>
				<Select
					placeholder={
						props.isLoading ? 'Loading...' : placeholder || 'Select a value...'
					}
					hideSelectedOptions={false}
					closeMenuOnSelect={!props.isMulti}
					menuIsOpen={menuIsOpen}
					onMenuOpen={() => setMenuIsOpen(true)}
					onMenuClose={() => setMenuIsOpen(false)}
					styles={{
						container: (baseStyles) => ({
							...baseStyles,
							width: '100%',
							minHeight: '36px',
						}),
						control: (baseStyles, state) => ({
							...baseStyles,
							borderColor: '#E6E6E6',
							background: COLORS.whiteAlpha[12],
							borderRadius: '8px',
							cursor: 'pointer',
							paddigLeft: '2px',
							boxShadow: state.isFocused ? SHADOWS.focused : 'none',
							'&:hover': {
								borderColor: state.isFocused
									? 'transparent'
									: COLORS.background[9],
							},
							...controlStyles,
						}),
						menu: (baseStyles) => ({
							...baseStyles,
							backgroundColor: COLORS.whiteAlpha[12],
							minWidth: '100%',
							width: 'max-content',
							maxWidth: '320px',
							borderColor: 'transparent',
							zIndex: 999,
							borderRadius: '8px',
							boxShadow: SHADOWS.popover,
						}),
						menuList: (baseStyles) => ({
							...baseStyles,
							maxHeight: '234px',
							padding: '4px',
						}),
						singleValue: (baseStyles) => ({
							...baseStyles,
							color: COLORS.gray[12],
							fontSize: '14px',
							margin: '0',
							paddingLeft: '2px',
						}),
						placeholder: (baseStyles) => ({
							...baseStyles,
							color: COLORS.background[6],
							fontSize: '14px',
							fontWeight: WEIGHT.medium,
						}),
					}}
					isClearable={isClearable}
					clearValue={clearValue}
					components={{
						SingleValue: (props) => (
							<SingleValue ref={singleValueRef} {...props} />
						),
					}}
					{...props}
				/>
			</Flex>
		</Tooltip>
	)
}
