const HIDE_SLACK_NOTIFICATIONS_SETTINGS_FLAG = true

const ENV = process.env.NODE_ENV

const isDev = ENV === 'development'
const isProd = ENV === 'production'

const DEFAULT_FE_URL = import.meta.env.VITE_APP_DEFAULT_FE_URL
const DEMETER_URL = import.meta.env.VITE_APP_DEMETER_URL
const REVALIDATE_SECRET_KEY = import.meta.env.VITE_APP_REVALIDATE_SECRET_KEY
const GOOGLE_REDIRECT = import.meta.env.VITE_APP_GOOGLE_REDIRECT
const ZOOM_REDIRECT = import.meta.env.VITE_APP_ZOOM_ENCODED_REDIRECT
const CHRONOS_URL = import.meta.env.VITE_APP_CHRONOS_URL
const NUCLEUS_URL = import.meta.env.VITE_APP_NUCLEUS_URL
const SENTRY_TOKEN = import.meta.env.VITE_APP_SENTRY_TOKEN
const POST_REDIRECT_URI_ONBOARDING = import.meta.env
	.VITE_APP_POST_REDIRECT_URI_ONBOARDING
const VITE_LAUNCHDARKLY_SDK_KEY = import.meta.env.VITE_LAUNCHDARKLY_SDK_KEY

export {
	CHRONOS_URL,
	DEFAULT_FE_URL,
	DEMETER_URL,
	GOOGLE_REDIRECT,
	HIDE_SLACK_NOTIFICATIONS_SETTINGS_FLAG,
	isDev,
	isProd,
	NUCLEUS_URL,
	POST_REDIRECT_URI_ONBOARDING,
	REVALIDATE_SECRET_KEY,
	SENTRY_TOKEN,
	VITE_LAUNCHDARKLY_SDK_KEY,
	ZOOM_REDIRECT,
}
