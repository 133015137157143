import { Flex } from '@chakra-ui/react'

import { useMainContentMaxWidth } from '@/common/hooks/useSidebarWidth'

type Props = {
	children: React.ReactNode
}

export const HomeContentLayout = ({ children }: Props) => {
	const mainContentMaxWidth = useMainContentMaxWidth()

	return (
		<Flex
			as="main"
			w="full"
			maxW={mainContentMaxWidth}
			h={['calc(100vh - 72px)', 'auto']}
			transition="max-width 250ms ease-in-out"
		>
			{children}
		</Flex>
	)
}
