import {
	Center,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useOutsideClick,
} from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'
import { createRef, useCallback, useState } from 'react'
import { GetIcon, Icons } from 'ui'

import { Radii } from '@/styles/borders'
import { Borders } from '@/styles/borders'
import { TEXT_ENUM } from '@/styles/components/text'
import { SHADOWS } from '@/styles/constants'

export type WorkflowMenuItemType = {
	title: string
	onClick: (formId: number) => void
	icon: Icons
	variant?: 'default' | 'danger'
}

type Props = {
	formId: number
	variant?: 'card' | 'list'
	menuItems?: WorkflowMenuItemType[]
}

export const WorkflowMenuActions = ({
	variant = 'list',
	menuItems,
	formId,
}: Props) => {
	const [isOpen, setIsOpen] = useState(false)

	const ref = createRef<HTMLButtonElement>()

	useOutsideClick({
		ref: ref,
		handler: () => setIsOpen(false),
	})

	const getOpacity = useCallback(() => {
		if (variant === 'list') return 1

		return isOpen ? 1 : 0
	}, [variant, isOpen])

	return (
		<Menu isLazy isOpen={isOpen}>
			<MenuButton
				onClickCapture={(e) => {
					e.stopPropagation()
					setIsOpen(!isOpen)
				}}
				ref={ref}
				p={0}
				w={6}
				h={6}
				bg={variant === 'card' ? ColorTokens.bg_card : 'transparent'}
				borderRadius={Radii.md}
				boxShadow={variant === 'card' ? SHADOWS.elevated : 'none'}
				border={variant === 'card' ? Borders.primary : 'none'}
				opacity={getOpacity()}
				_hover={{
					bg: variant === 'card' ? ColorTokens.bg_card : 'transparent',
				}}
				_groupHover={{
					opacity: 1,
				}}
				_active={{
					boxShadow: 'none',
				}}
			>
				<Center>
					<GetIcon
						icon={
							variant === 'card' ? Icons.dots_horizontal : Icons.dots_vertical
						}
						boxSize={5}
						color={COLORS.gray[10]}
					/>
				</Center>
			</MenuButton>

			<MenuList>
				{menuItems?.map((item) => (
					<MenuItem
						key={item.title}
						onClick={(e) => {
							e.stopPropagation()
							e.preventDefault()
							item.onClick(formId)
						}}
					>
						<GetIcon
							icon={item.icon}
							mr={3}
							boxSize={5}
							color={
								item?.variant === 'danger'
									? COLORS.red[9]
									: COLORS.background[5]
							}
						/>
						<Text
							variant={TEXT_ENUM.medium14}
							color={
								item?.variant === 'danger'
									? COLORS.red[9]
									: COLORS.background[2]
							}
						>
							{item.title}
						</Text>
					</MenuItem>
				))}
			</MenuList>
		</Menu>
	)
}
