import { CheckCircleIcon, InfoIcon } from '@chakra-ui/icons'
import {
	AlertStatus,
	Box,
	CloseButton,
	Flex,
	Text,
	ToastId,
} from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'

import { TEXT_ENUM } from '@/styles/components/text'
import { SHADOWS } from '@/styles/constants'

interface BodyProps {
	id?: ToastId
	onClose: () => void
	status?: AlertStatus
	title?: React.ReactNode
	description?: React.ReactNode
}

const BANNER_WIDTH = '350px'

const Body = ({ status, title, description, onClose }: BodyProps) => {
	return (
		<Box
			bg={
				status === 'success'
					? ColorTokens.success_background
					: status === 'error'
						? ColorTokens.danger_background
						: status === 'warning'
							? ColorTokens.warning_background
							: COLORS.gray[12]
			}
			p={4}
			borderRadius="md"
			borderWidth="thin"
			borderColor={
				status === 'success'
					? ColorTokens.success
					: status === 'error'
						? ColorTokens.danger
						: status === 'warning'
							? ColorTokens.warning
							: ColorTokens.border_primary
			}
			boxShadow={
				status === 'success'
					? SHADOWS.success
					: status === 'error'
						? SHADOWS.error
						: status === 'warning'
							? SHADOWS.warning
							: SHADOWS.depth0
			}
			width={BANNER_WIDTH}
		>
			<Flex align="center" justify="space-between">
				<Flex maxW="90%">
					<Icon status={status} />
					<Text
						variant={TEXT_ENUM.h5}
						color={
							status === 'success'
								? ColorTokens.success
								: status === 'error'
									? ColorTokens.danger
									: status === 'warning'
										? ColorTokens.warning
										: ColorTokens.border_primary
						}
						noOfLines={2}
					>
						{title}
					</Text>
				</Flex>
				<CloseButton
					size="sm"
					fill={ColorTokens.text_inverted}
					color={
						status === 'success'
							? ColorTokens.success
							: status === 'error'
								? ColorTokens.danger
								: status === 'warning'
									? ColorTokens.warning
									: ColorTokens.border_primary
					}
					onClick={onClose}
				/>
			</Flex>
			<Text
				variant={TEXT_ENUM.xs}
				color={
					status === 'success'
						? ColorTokens.success
						: status === 'error'
							? ColorTokens.danger
							: status === 'warning'
								? ColorTokens.warning
								: ColorTokens.border_primary
				}
				fontWeight="medium"
				noOfLines={4}
				ml={6}
				mt={0.5}
			>
				{description}
			</Text>
		</Box>
	)
}
export default Body

const Icon = ({ status }: { status?: AlertStatus }) => {
	return (
		<Flex mr={2} alignItems="center" justifyContent="center">
			{getToastIcon(status)}
		</Flex>
	)
}

const getToastIcon = (status?: AlertStatus) => {
	switch (status) {
		case 'success':
			return (
				<CheckCircleIcon
					fill={ColorTokens.success}
					color={ColorTokens.success}
					fontSize="16px"
				/>
			)
		case 'error':
			return (
				<InfoIcon
					fill={ColorTokens.danger}
					color={ColorTokens.danger}
					fontSize="16px"
				/>
			)
		case 'warning':
			return (
				<InfoIcon
					fill={ColorTokens.warning}
					color={ColorTokens.warning}
					fontSize="16px"
				/>
			)
		default:
			return (
				<InfoIcon
					fill={ColorTokens.text_inverted}
					color={ColorTokens.text_inverted}
					fontSize="16px"
				/>
			)
	}
}
