import './styles/index.css'
import './wdyr'

import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { StrictMode, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { store } from './app/store'
import { ErrorBoundaryComponent } from './components/ErrorBoundary'
import LoadingPage from './components/Loading/LoadingPage'
import { App } from './modules/app'
import { LaunchDarklyProvider } from './Providers/LaunchDarklyProvider'
import { LocalizationProvider } from './Providers/LocalizationProvider'
import defaultTheme from './styles/themeConfig'

declare global {
	interface Window {
		paragon: any
		Intercom: any
	}
}

const domNode = document.getElementById('root') as HTMLElement

Sentry.init({
	dsn: 'https://01fdbe850e734be397997a62dde5585e@o1180762.ingest.us.sentry.io/6295413',
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	tracesSampleRate: 1.0,
	tracePropagationTargets: ['https://forms.default.com/*'],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
})

const root = createRoot(domNode)

root.render(
	<StrictMode>
		<ChakraProvider theme={defaultTheme}>
			<ColorModeScript
				initialColorMode={defaultTheme.config.initialColorMode}
			/>
			<Provider store={store}>
				<ErrorBoundaryComponent>
					<BrowserRouter>
						<Suspense fallback={<LoadingPage />}>
							<LaunchDarklyProvider>
								<LocalizationProvider>
									<App />
								</LocalizationProvider>
							</LaunchDarklyProvider>
						</Suspense>
					</BrowserRouter>
				</ErrorBoundaryComponent>
			</Provider>
		</ChakraProvider>
	</StrictMode>,
)
