import { HStack, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { getFullNameOrEmail } from 'shared-utils'

import { UpdatedBy } from '@/app/user/api/members/type'
import { Dot } from '@/common/components/Dot'
import { TEXT_ENUM } from '@/styles/components/text'
import { tsToTimeAgo } from '@/utils/helpers/date-time'

type Props = {
	updatedBy?: UpdatedBy | null
	updatedAt?: string | null
}

export const WorkflowModifyBy = ({ updatedBy, updatedAt }: Props) => {
	const fullName = getFullNameOrEmail({
		firstName: updatedBy?.first_name,
		lastName: updatedBy?.last_name,
		email: updatedBy?.email,
	})

	const modifiedAt = `Modified ${tsToTimeAgo(
		new Date(updatedAt || Date.now()).getTime(),
	)}`

	return (
		<HStack color={COLORS.background[5]}>
			{updatedBy && (
				<>
					<Text variant={TEXT_ENUM.regular12}>{`By ${fullName}`}</Text>
					<Dot />
				</>
			)}
			{updatedAt && <Text variant={TEXT_ENUM.regular12}>{modifiedAt}</Text>}
		</HStack>
	)
}
