import { Flex } from '@chakra-ui/react'
import { SingleValue } from 'react-select'

import { useGetSalesforceFieldsQuery } from '@/app/integrations/api/salesforce'
import { useUpdateWorkflowNode } from '@/modules/workflow/hooks'
import {
	SalesforceRecordOption,
	useSalesforceRecordOptionsByType,
} from '@/modules/workflow/hooks/useSalesforceRecordOptionsByType'
import { useSelectedNode } from '@/modules/workflow/hooks/workflow'
import {
	Salesforce_MergeRecord,
	SalesforceEntities,
} from '@/modules/workflow/types/salesforce'

import { PanelInputLabel } from '../../../../panel-variants/PanelInputLabel'
import { PanelSelect } from '../../../../panel-variants/PanelSelect'
import { FieldMapper } from '../../FieldMapper'

export const SalesforceMergeRecord = () => {
	const updateNode = useUpdateWorkflowNode()
	const selectedNode = useSelectedNode()

	const stepDetails = (selectedNode?.data?.stepDetails ||
		{}) as Salesforce_MergeRecord
	const { duplicateRecordId, type } = stepDetails

	const { getSalesforceRecordOptions } = useSalesforceRecordOptionsByType()
	const recordOptions: SalesforceRecordOption[] = getSalesforceRecordOptions(
		type?.value || '',
	)

	const { data: fieldsData, isLoading: isFieldDataLoading } =
		useGetSalesforceFieldsQuery(
			{ type: type?.value || '', filterOutReadOnlyFields: true },
			{
				skip: !type?.value,
			},
		)
	const fields = Array.isArray(fieldsData) ? fieldsData : []

	const updateNodeStepDetails = (newStepDetails: Salesforce_MergeRecord) => {
		if (!selectedNode) return
		updateNode({
			...selectedNode,
			data: {
				...selectedNode.data,
				stepDetails: newStepDetails,
			},
		})
	}

	const recordTypeOptions = Object.values(SalesforceEntities).map((type) => ({
		label: type,
		value: type,
	}))

	const handleSelectRecordType = (
		entityTypeOp: SingleValue<{
			label: SalesforceEntities
			value: SalesforceEntities
		}>,
	) => {
		if (!entityTypeOp) return

		const newStepDetails: Salesforce_MergeRecord = {
			type: {
				refNodeId: null,
				variable: null,
				value: entityTypeOp.value,
			},
			insertSalesforceFields: {},
			duplicateRecordId: {
				refNodeId: null,
				variable: null,
				value: null,
				label: null,
			},
			overwrite: false,
		}

		updateNodeStepDetails(newStepDetails)
	}

	const handleSelectRecord = (
		recordOp: SingleValue<SalesforceRecordOption>,
	) => {
		if (!recordOp) return

		const newStepDetails: Salesforce_MergeRecord = {
			...stepDetails,
			duplicateRecordId: {
				refNodeId: recordOp.value,
				variable: recordOp.variable, // this refers to { ... Id }.id
				value: null,
				label: recordOp.label,
			},
		}

		updateNodeStepDetails(newStepDetails)
	}

	const recordTypeValue = recordTypeOptions.find(
		(op) => op.value === type?.value,
	)

	const recordValue = recordOptions.find(
		(op) =>
			op.value === duplicateRecordId?.refNodeId &&
			op.variable === duplicateRecordId?.variable,
	)

	return (
		<Flex w="100%" direction="column" gap={4}>
			<Flex direction="column">
				<PanelInputLabel label="Type" />
				<PanelSelect
					placeholder="Select a record type"
					value={recordTypeValue || null}
					options={recordTypeOptions}
					onChange={handleSelectRecordType}
				/>
			</Flex>

			{type && (
				<>
					<Flex direction="column">
						<PanelInputLabel label="Record to update" />
						<PanelSelect
							placeholder="Select a record"
							value={recordValue || null}
							options={recordOptions}
							onChange={handleSelectRecord}
						/>
					</Flex>
					<FieldMapper
						integration="Salesforce"
						fields={fields}
						isDataLoading={isFieldDataLoading}
					/>
				</>
			)}
		</Flex>
	)
}
