import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface FormsState {
	selectedFormId: string | null
	selectedBlock: string | null
	isDragging: boolean
	lead_attribute_edited: number | null
	selectedAction: string | null
}

export const initialState: FormsState = {
	selectedFormId: null,
	lead_attribute_edited: null,
	selectedBlock: null,
	isDragging: false,
	selectedAction: null,
}

export const formsSlice = createSlice({
	name: 'forms',
	initialState,
	reducers: {
		setSelectFormId: (state, { payload }: PayloadAction<string | null>) => {
			state.selectedFormId = payload
		},
		selectBlock: (
			state,
			{ payload: { path } }: PayloadAction<{ path: string | null }>,
		) => {
			state.selectedBlock = path
		},
		setIsDragging: (state, action: PayloadAction<boolean>) => {
			state.isDragging = action.payload
		},

		updateLeadAttribute: (state, { payload }: PayloadAction<number | null>) => {
			state.lead_attribute_edited = payload
		},
		updateSelectedAction: (
			state,
			{ payload }: PayloadAction<string | null>,
		) => {
			state.selectedAction = payload
		},
	},
})
export const formsActions = formsSlice.actions
export const formsReducer = formsSlice.reducer
