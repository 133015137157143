import { Flex } from '@chakra-ui/react'

import { useUpdateWorkflowNode } from '@/modules/workflow/hooks'
import { useSelectedNode } from '@/modules/workflow/hooks/workflow'
import { MatchRecord } from '@/modules/workflow/types/matching'
import { SalesforceEntities } from '@/modules/workflow/types/salesforce'

import {
	ConditionValueInput,
	MatchConditionComparatorInput,
	PriorityConditionComparatorInput,
} from '../../matching/conditionInputs'
import { MatchingCriteria } from '../../matching/MatchingCriteria'
import { PriorityCriteria } from '../../matching/PriorityCriteria'
import { RecordTypeSelect } from '../../matching/RecordTypeSelect'
import { ConditionParameterInput } from './ConditionParameterInput'

export const SalesforceMatchRecord = () => {
	const updateNode = useUpdateWorkflowNode()
	const selectedNode = useSelectedNode()

	const recordTypeOptions = Object.values(SalesforceEntities).map((type) => ({
		label: type,
		value: type,
	}))

	const isRecordTypeSelected = (selectedNode?.data.stepDetails as MatchRecord)
		?.type?.value

	const matchConditionComponents = {
		ConditionParameterInput,
		ConditionComparatorInput: MatchConditionComparatorInput,
		ConditionValueInput,
	}

	const priorityConditionComponents = {
		ConditionParameterInput,
		ConditionComparatorInput: PriorityConditionComparatorInput,
		ConditionValueInput,
	}

	return (
		<Flex w="100%" direction="column" gap={4}>
			<RecordTypeSelect
				selectedNode={selectedNode}
				updateNode={updateNode}
				options={recordTypeOptions}
			/>

			{isRecordTypeSelected && (
				<Flex direction="column" gap={10}>
					<MatchingCriteria
						selectedNode={selectedNode}
						updateNode={updateNode}
						conditionComponents={matchConditionComponents}
					/>
					<PriorityCriteria
						selectedNode={selectedNode}
						updateNode={updateNode}
						conditionComponents={priorityConditionComponents}
					/>
				</Flex>
			)}
		</Flex>
	)
}
