import { useCallback } from 'react'

import { useAppSelector } from '@/app/hooks'
import HubSpotLogo from '@/assets/logo/hubspot-step.svg'
import SalesforceLogo from '@/assets/logo/salesforce-step.svg'

import { AsyncDrpOptionGenerator } from '../../../components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '../../../components/side-panel/panel-content/DataReferencePicker/components/Option'
import {
	selectHubspotProperties,
	selectSalesforceFields,
} from '../../../slice/selectors'
import { Hubspot_MatchRecord } from '../../../types/hubspot'
import { Salesforce_MatchRecord } from '../../../types/salesforce'
import { WorkflowIntegrationIds } from '../../../utils/mappings'
import { useAllHubspotProperties } from '../../useAllHubspotProperties'
import { useAllSalesforceFields } from '../../useAllSalesforceFields'
import { useGetUpstreamNodes } from '../../useGetUpstreamNodes'

const VALID_CRM_OWNER_INTEGRATIONS = {
	[WorkflowIntegrationIds.salesforceMatchRecord]: {
		icon: SalesforceLogo,
		fetchMappedUserIntegration: 'salesforce',
	},
	[WorkflowIntegrationIds.hubspotMatchRecord]: {
		icon: HubSpotLogo,
		fetchMappedUserIntegration: 'hubspot',
	},
}

export const useGetCrmOwnerDrpOptions = (): AsyncDrpOptionGenerator => {
	const { getUpstreamNodes } = useGetUpstreamNodes()

	const getAllHubspotProperties = useAllHubspotProperties()
	const propertiesInStore = useAppSelector(selectHubspotProperties)

	const getAllSalesforceFields = useAllSalesforceFields()
	const fieldsInStore = useAppSelector(selectSalesforceFields)

	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			const upstreamNodes = getUpstreamNodes()
			const ownerNodes = upstreamNodes.filter((n) =>
				Object.keys(VALID_CRM_OWNER_INTEGRATIONS).includes(
					n.data.integrationId,
				),
			)

			const hubSpotProperties =
				propertiesInStore || (await getAllHubspotProperties())

			const salesforceFields = fieldsInStore || (await getAllSalesforceFields())

			const allDrpOptions: Record<string, DataReferencePickerOption[]> = {}

			Object.values(VALID_CRM_OWNER_INTEGRATIONS).forEach(
				({ fetchMappedUserIntegration }) => {
					allDrpOptions[fetchMappedUserIntegration] = []
				},
			)

			ownerNodes.forEach((n) => {
				const objectType = (
					n.data?.stepDetails as Hubspot_MatchRecord | Salesforce_MatchRecord
				)?.type?.value

				const integrationId = n.data.integrationId

				const { icon, fetchMappedUserIntegration } =
					VALID_CRM_OWNER_INTEGRATIONS[integrationId]

				const filteredProps =
					fetchMappedUserIntegration === 'hubspot'
						? hubSpotProperties[objectType].filter(
								(property) => property.referencedObjectType === 'OWNER',
							)
						: salesforceFields[objectType].filter((field) =>
								field.referenceTo?.includes('User'),
							)

				allDrpOptions[fetchMappedUserIntegration].push({
					label: `${objectType} from "${n.data.editableName}"`,
					icon,
					value: filteredProps?.map((prop) => {
						const newRfn = {
							refNodeId: n.data.id,
							variable: `$.${prop.name}`,
							value: null,
							fetchMappedUserIntegration,
							type: 'member',
							label: prop.label,
							icon,
						}
						return {
							label: prop.label,
							value: newRfn,
							onSelect: (value: any) => {
								onSelect(value)
							},
						}
					}),
				})
			})

			const objectTypesCovered: string[] = []

			const ownerNodesWithObjectTypes = ownerNodes.filter(
				(n) =>
					(n.data?.stepDetails as Hubspot_MatchRecord | Salesforce_MatchRecord)
						?.type?.value,
			)

			ownerNodesWithObjectTypes.forEach((n) => {
				const objectType = (
					n.data?.stepDetails as Hubspot_MatchRecord | Salesforce_MatchRecord
				)?.type?.value

				if (!objectTypesCovered.includes(objectType)) {
					objectTypesCovered.push(objectType)

					const integrationId = n.data.integrationId

					const { icon, fetchMappedUserIntegration } =
						VALID_CRM_OWNER_INTEGRATIONS[integrationId]

					const filteredProps =
						fetchMappedUserIntegration === 'hubspot'
							? hubSpotProperties[objectType].filter(
									(property) => property.referencedObjectType === 'OWNER',
								)
							: salesforceFields[objectType].filter((field) =>
									field.referenceTo?.includes('User'),
								)

					allDrpOptions[fetchMappedUserIntegration].unshift({
						label: `Previous ${objectType} record`,
						icon,
						value: filteredProps?.map((prop) => {
							const newRfn = {
								refNodeId: 'records',
								variable: `$.${fetchMappedUserIntegration}Records.${objectType}.${prop.name}`,
								value: null,
								fetchMappedUserIntegration,
								dataType: prop.type,
								type: 'member',
								label: prop.label,
								icon,
							}

							return {
								label: prop.label,
								value: newRfn,
								onSelect: (value: any) => {
									onSelect(value)
								},
							}
						}),
					})
				}
			})

			return [
				{
					label: 'Salesforce owner records',
					icon: SalesforceLogo,
					value: allDrpOptions.salesforce,
				},
				{
					label: 'HubSpot owner records',
					icon: HubSpotLogo,
					value: allDrpOptions.hubspot,
				},
			]
		},
		[
			fieldsInStore,
			getAllHubspotProperties,
			getAllSalesforceFields,
			getUpstreamNodes,
			propertiesInStore,
		],
	)
}
